<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M2 6V2.88889C2 2.65314 2.09365 2.42705 2.26035 2.26035C2.42705 2.09365 2.65314 2 2.88889 2H6" stroke="#B4B8B6" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
    <path d="M10 2H13.1111C13.3469 2 13.573 2.09365 13.7397 2.26035C13.9063 2.42705 14 2.65314 14 2.88889V6" stroke="#B4B8B6" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
    <path d="M14 10V13.1111C14 13.3469 13.9063 13.573 13.7397 13.7397C13.573 13.9063 13.3469 14 13.1111 14H10" stroke="#B4B8B6" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
    <path d="M6 14H2.88889C2.65314 14 2.42705 13.9063 2.26035 13.7397C2.09365 13.573 2 13.3469 2 13.1111V10" stroke="#B4B8B6" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
    <path d="M6 6H10V10" stroke="#7A8080" stroke-width="2" stroke-linecap="square"/>
  </svg>
</template>
<script>
export default {
  name: "FullWidthSvg"
};
</script>



<style scoped lang='scss'>

</style>