import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import { createVfm } from 'vue-final-modal';
import 'vue-final-modal/style.css';
import 'vue-select/dist/vue-select.css';
import { io } from 'socket.io-client';

const socket = io(process.env.VUE_APP_URL);
socket.io.reconnectionDelay(5000)
const vfm = createVfm();

const app = createApp(App);

app.provide('io',socket)

app.use(store).use(router).use(vfm).mount('#app');
