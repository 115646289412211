<template>
  <ModalsContainer />
</template>
<script>
import { ModalsContainer, useModal } from "vue-final-modal";
import RegistrationModal from "@/components/modals/RegistrationModal.vue";
import SubscriptionModal from "@/components/modals/SubscriptionModal.vue";
import PaymentSubscriptionModal from "@/components/modals/PaymentSubscriptionModal.vue";
import EmailConfirmationModal from "@/components/modals/EmailConfirmationModal.vue";
import LoginModal from "@/components/modals/LoginModal.vue";
import { baseErrHandler, setFreeSubscription } from "@/services/api";
import ForgotPasswordModal from "@/components/modals/ForgotPasswordModal.vue";
import { mapGetters } from "vuex";

export default {
  name: "ModalsContainerRegistrationLogin",
  props: {
    email: {
      type: String,
      default: ''
    }
  },
  data(){
    return{
      modalRegistration: null,
      modalLogin: null,
      modalSubscription: null,
      modalPaySubscription: null,
      modalConfirmationEmail: null,
      modalForgotPassword: null,
    }
  },
  components: { ModalsContainer },

  methods:{
    async openModalRegistration() {
      this.modalRegistration = useModal({
        component: RegistrationModal,
        attrs: {
          email_l:this.email,
          onRegistered: this.registeredUser,
          onClose: this.closeModalRegistration
        }
      });
      this.modalRegistration.open();
    },
    async openModalForgotPassword() {
      this.modalForgotPassword = useModal({
        component: ForgotPasswordModal,
        attrs: {
          onClose: this.closeModalForgotPassword
        }
      });
      this.modalForgotPassword.open();
    },
    async openModalSubscription() {
      this.modalSubscription = useModal({
        component: SubscriptionModal,
        attrs: {
          onConfirm: this.checkSubscription,
          onSkip: this.skipModal,
          onClose: this.closeModalSubscription
        }
      });
      this.modalSubscription.open();
    },
    async openModalPaySubscription(val) {
      this.modalPaySubscription = useModal({
        component: PaymentSubscriptionModal,
        attrs: {
          subscription : val,
          onBack: this.paymentGoToBack,
          onPayment: this.paymentPlanSubscription,
          onClose: this.closeModalPaySubscription
        }
      });
      this.modalPaySubscription.open();
    },
    async openModalConfirmationEmail() {
      this.modalConfirmationEmail = useModal({
        component: EmailConfirmationModal,
        attrs: {
          onClose: this.closeModalConfirmationEmail
        }
      });
      this.modalConfirmationEmail.open();
    },
   async checkSubscription(val){
      this.closeModalSubscription()
      if (val.sub_val==='free') {
        await baseErrHandler(async () => {
          await setFreeSubscription();
        });
        if (!this.getLoggedUser?.confirmed) {
          await this.openModalConfirmationEmail()
        }
      }else{
        await this.openModalPaySubscription(val)
      }
    },
    skipModal(){
      this.modalSubscription.close();
      this.openModalConfirmationEmail()
    },
    registeredUser(){
      this.modalRegistration.close();
      this.openModalSubscription()
    },
    closeModalConfirmationEmail(){
      if (this.modalConfirmationEmail) {
        this.modalConfirmationEmail.close();
      }
    },
    paymentPlanSubscription(){
      this.closeModalPaySubscription()
      this.$emit("paymentSubscription")
    },
    paymentGoToBack(){
      if (this.modalPaySubscription) {
        this.modalPaySubscription.close();
      }
      this.openModalSubscription()
    },
    closeModalPaySubscription() {
      if (this.modalPaySubscription) {
        this.modalPaySubscription.close();
      }
      if (!this.getLoggedUser?.confirmed) {
         this.openModalConfirmationEmail()
      }
    },
    closeModalForgotPassword() {
      if (this.modalForgotPassword) {
        this.modalForgotPassword.close();
      }
    },
    closeModalSubscription() {
      if (this.modalSubscription) {
        this.modalSubscription.close();
      }
    },
    closeModalRegistration() {
      if (this.modalRegistration) {
        this.modalRegistration.close();
      }
    },
    async openModalLogin() {
      this.modalLogin = useModal({
        component: LoginModal,
        attrs: {
          onForgotPassword: this.openForgotPasswordModal,
          onClose: this.closeModalLogin
        }
      });
      this.modalLogin.open();
    },
    closeModalLogin() {
      if (this.modalLogin) {
        this.modalLogin.close();
      }
    },
    openForgotPasswordModal(){
      this.closeModalLogin()
      this.openModalForgotPassword()
    }
  },
  computed: {
    ...mapGetters([
      "getLoggedUser"
    ]),
  }
};
</script>

<style scoped lang='scss'>

</style>