<template>
  <VueFinalModal
    class='container_modal'
    content-class='modal_content'
    content-transition='vfm-fade'
  >
    <div class='header_modal'>
      <logo class='logo' />
      <svg @click="$emit('close')" width='1em' height='1em' viewBox='0 0 10 10' fill='none'
           xmlns='http://www.w3.org/2000/svg'
           class='cp text-xl text-violet-medium'>
        <path d='M8.5 1.5L1.5 8.5' stroke='#ffffff' stroke-width='2' stroke-linecap='round'
              stroke-linejoin='round'></path>
        <path d='M1.5 1.5L8.5 8.5' stroke='#ffffff' stroke-width='2' stroke-linecap='round'
              stroke-linejoin='round'></path>
      </svg>
    </div>
    <div>
      <div class='welcome'>
        <span class='text_welcome'>welcome back</span>
      </div>
<!--      <div class='text_title'>Welcome back!</div>-->
<!--      <div class='text'>Connect with great minds and gain essential-->
<!--        skills and knowledge from them.-->
<!--      </div>-->
      <div class='form_login'>
        <div class='input-wrapper'>
          <svg class='input-icon' width='1em' height='1em' viewBox='0 0 16 16' fill='none'
               xmlns='http://www.w3.org/2000/svg'>
            <path opacity='0.9'
                  d='M15 11.1828C15 13.0119 13.432 14.4934 11.479 14.5H11.472H4.535C2.589 14.5 1 13.025 1 11.1959V11.1894C1 11.1894 1.0042 8.28782 1.0098 6.82852C1.0105 6.55449 1.3465 6.40109 1.5754 6.57154C3.2386 7.80729 6.2129 10.0605 6.25 10.09C6.747 10.463 7.377 10.6734 8.021 10.6734C8.665 10.6734 9.295 10.463 9.792 10.0828C9.8291 10.0598 12.7369 7.87416 14.4253 6.61808C14.6549 6.44698 14.9923 6.60038 14.993 6.87376C15 8.32191 15 11.1828 15 11.1828Z'
                  fill='#666'></path>
            <path
              d='M14.4312 3.31376C13.841 2.20678 12.6796 1.5 11.401 1.5H4.59902C3.32041 1.5 2.15904 2.20678 1.56881 3.31376C1.43658 3.56134 1.49929 3.86996 1.71943 4.04496L6.78001 8.07335C7.13442 8.35824 7.5638 8.5 7.99318 8.5C7.99591 8.5 7.99796 8.5 8 8.5C8.00204 8.5 8.00477 8.5 8.00682 8.5C8.4362 8.5 8.86558 8.35824 9.21999 8.07335L14.2806 4.04496C14.5007 3.86996 14.5634 3.56134 14.4312 3.31376Z'
              fill='#666'>
            </path>
          </svg>
          <input id='email' v-model='email' class='input-style' placeholder=' ' type='email'>
          <label for='email' class='label_placeholder'>email address</label>
          <label v-if='valid_email' for='' class='label_valid'>{{ valid_email }}</label>
        </div>
        <div class='input-wrapper'>
          <svg class='input-icon' width='1em' height='1em' viewBox='0 0 16 16' fill='none'
               xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M11.4845 5C13.4259 5 15 6.54757 15 8.45632V12.5437C15 14.4524 13.4259 16 11.4845 16H4.51548C2.57406 16 1 14.4524 1 12.5437V8.45632C1 6.54757 2.57406 5 4.51548 5H11.4845ZM7.99582 8.86687C7.59414 8.86687 7.26778 9.18773 7.26778 9.58265V11.4091C7.26778 11.8123 7.59414 12.1331 7.99582 12.1331C8.40586 12.1331 8.73222 11.8123 8.73222 11.4091V9.58265C8.73222 9.18773 8.40586 8.86687 7.99582 8.86687Z'
              fill='#666'></path>
            <path opacity='0.9'
                  d='M12.5 4.31676V5.49333C12.2102 5.41384 11.9039 5.37409 11.5892 5.37409H11.0511V4.31676C11.0511 2.70294 9.68491 1.39122 8.00414 1.39122C6.32337 1.39122 4.95722 2.69499 4.94894 4.30086V5.37409H4.41904C4.09614 5.37409 3.78979 5.41384 3.5 5.50128V4.31676C3.50828 1.93181 5.52024 0 7.98758 0C10.488 0 12.5 1.93181 12.5 4.31676Z'
                  fill='#666'></path>
          </svg>
          <input v-model='password' id='passwordInput' class='input-style' placeholder=' ' type='password'>
          <label for='passwordInput' class='label_placeholder'>password</label>
          <label v-if='valid_password' for='' class='label_valid'>{{ valid_password }}</label>
          <svg @click='togglePasswordVisibility' class='toggle-icon' width='1em' height='1em' viewBox='0 0 16 16'
               fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path opacity='0.4' fill-rule='evenodd' clip-rule='evenodd'
                  d='M12.5893 3.53455C11.2234 2.53818 9.65463 2 8 2H7.9922C4.69073 2 1.71707 4.15927 0.0468293 7.78182C-0.0156098 7.91927 -0.0156098 8.08 0.0468293 8.21091C1.71707 11.8327 4.69073 14 7.9922 14H8C11.3093 14 14.2829 11.8327 15.9532 8.21091C16.0156 8.08 16.0156 7.91927 15.9532 7.78182C15.118 5.97091 13.9551 4.52291 12.5893 3.53455ZM8 11C9.933 11 11.5 9.65685 11.5 8C11.5 6.34315 9.933 5 8 5C6.067 5 4.5 6.34315 4.5 8C4.5 9.65685 6.067 11 8 11Z'
                  fill='#F3F6F4'></path>
            <path
              d='M10 8.00401C10 9.1022 9.1022 10 8.00401 10C6.8978 10 6 9.1022 6 8.00401C6 7.86774 6.01603 7.74028 6.04008 7.61202H6.08016C6.96994 7.61202 7.69138 6.90661 7.72345 6.02405C7.81162 6.00882 7.90782 6 8.00401 6C9.1022 6 10 6.8978 10 8.00401Z'
              fill='#666'></path>
          </svg>
        </div>
      </div>
      <div class='sharing_your_mail'> Forgot password? Press <span class='text_gray cp' @click="$emit('forgot-password')">here</span></div>
      <div class='btn-red mt-16' @click='login'>login</div>
    </div>
  </VueFinalModal>
</template>

<script>
import { VueFinalModal } from "vue-final-modal";
import Logo from "@/components/svg/logo.vue";
import { isInValidEmail } from "@/helpers/validate.helper";
import { mapMutations } from "vuex";
import { baseErrHandler, login } from "@/services/api";
import { UserRoles } from "@/enums/enums";

export default {
  name: "LoginModal",
  components: {
    Logo,
    VueFinalModal
  },
  data() {
    return {
      email: "",
      password: "",
      valid_password: "",
      valid_email: ""
    };
  },
  methods: {
    ...mapMutations([
      "setLoggedUser",
      "showLoader",
      "hideLoader"
    ]),
    clearForm() {
      this.valid_password = "";
      this.valid_email = "";
    },
    async login() {
      this.clearForm();
      let valid_form = true;
      if (!this.email) {
        this.valid_email = "This field is required";
        valid_form = false;
      } else if (isInValidEmail(this.email)) {
        this.valid_email = "Please check this field";
        valid_form = false;
      }
      if (!this.password) {
        this.valid_password = "This field is required";
        valid_form = false;
      }
      if (!valid_form) {
        return;
      }
      await baseErrHandler(async () => {
        const { user, access_token, error } = await login({ email: this.email, password: this.password });
        if (error){
          this.valid_password = error;
          return;
        }
        if (!access_token || !user) {
          this.valid_password = "Please check this field.";
          return;
        }
        if (access_token && user) {
          this.setLoggedUser(user);
          localStorage.setItem("panagenius_jwt", access_token);
          localStorage.setItem("panagenius_user", JSON.stringify(user));
        }
        if (user?.role === UserRoles.admin) {
          this.$emit("close");
          await this.$router.push("/admin/courses");
          return;
        }
      //  await this.$router.replace(this.$route.path);
        this.$emit("close");
        await this.$router.push('/dashboard');
      });
    },
    togglePasswordVisibility() {
      const passwordInput = document.getElementById("passwordInput");
      if (passwordInput.type === "password") {
        passwordInput.type = "text";
      } else {
        passwordInput.type = "password";
      }
    }
  }
};
</script>

<style>
.container_modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal_content {
  display: flex;
  flex-direction: column;
  background: #121314;
  width: 384px;
  padding: 24px;
  box-sizing: border-box;
}

@media (max-width: 992px) {
  .modal_content {
    width: 100%;
  }
}
</style>
<style scoped lang='scss'>
.input-style {
  width: 100%;
  height: 48px;
  padding-left: 30px;
}

.input-wrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
}

.input-icon {
  position: absolute;
  left: 10px;
  fill: #666;
  top: 15px;
}

.mt-16 {
  margin-top: 16px;
}

.header_modal {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.welcome {
  margin-top: 60px;
  background: radial-gradient(50.46% 72659998.73% at 49.54% 100.88%, #ffffff 0, rgba(4, 235, 159, 0) 100%);
  height: 1px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text_welcome {
  color: #FF4550;
  position: absolute;
  top: -10px;
  background: #121314;
  padding: 0 10px;
  text-transform: uppercase;
}

.text_title {
  color: white;
  font-size: 14px;
  margin-top: 20px;
  text-align: center;
}

.text {
  text-align: center;
  color: #959897;
  font-size: 12px;
  margin-top: 10px;
}

.sharing_your_mail {
  color: #959897;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-top: 16px;
}

.text_gray {
  color: #B4B8B6
}

.decoration {
  text-decoration: underline #B4B8B6;
}

.form_login {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 40px;
}

.toggle-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.label_placeholder{
  color: #959897;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
}
.input-style + .label_placeholder {
  position: absolute;
  top: 12px;
  left: 26px;
  transition: all 0.4s ease;
  pointer-events: none;
  padding: 0 5px;
}

.input-style:not(:placeholder-shown) + .label_placeholder,
.input-wrapper:focus-within .label_placeholder {
  top: -12px;
  background: #141616;
}
</style>