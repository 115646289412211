export const checkCurrentUser = () => {
  let user;
  try {
    user = JSON.parse(localStorage.getItem('panagenius_user'));
  } catch (e) {
    console.log(e);
  }
  if (!user?.id) {
    delete localStorage.panagenius_user;
    delete localStorage.panagenius_jwt;
  }
  return user;
};