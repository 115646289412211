<template>
  <VueFinalModal
    class='pay_subscription_modal'
    content-class='pay_subscription_modal_content'
    content-transition='vfm-fade'
  >
    <div class='header_modal'>
      <logo class='logo' />
      <svg @click="$emit('close')" width='1em' height='1em' viewBox='0 0 10 10' fill='none'
           xmlns='http://www.w3.org/2000/svg'
           class='cp text-xl text-violet-medium'>
        <path d='M8.5 1.5L1.5 8.5' stroke='#ffffff' stroke-width='2' stroke-linecap='round'
              stroke-linejoin='round'></path>
        <path d='M1.5 1.5L8.5 8.5' stroke='#ffffff' stroke-width='2' stroke-linecap='round'
              stroke-linejoin='round'></path>
      </svg>
    </div>
    <div>
      <div class='welcome'>
        <span class='text_welcome'>optional</span>
      </div>
      <div class='text_title'>Our Voices Matter! Now,<br /> More than Ever!</div>
      <div class='text'>Help us amplify our stories and bring you more premium content relevant to you and our communities.</div>
      <div class='form_box'>
        <PaymentFormCard v-if='subscription' :subscription='subscription' @back='$emit("back")' @payment='$emit("payment")' @close='$emit("close")'/>
      </div>
    </div>
  </VueFinalModal>
</template>

<script>
import { VueFinalModal } from "vue-final-modal";
import Logo from "@/components/svg/logo.vue";
import PaymentFormCard from "@/components/PaymentFormCard.vue";

export default {
  name: "PaymentSubscriptionModal",
  components: {
    PaymentFormCard,
    Logo,
    VueFinalModal
  },
  props:{
    subscription:{
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {

    };
  },
  methods: {

  },

};
</script>

<style>
.pay_subscription_modal {
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.pay_subscription_modal_content {
  display: flex;
  flex-direction: column;
  background: #121314;
  width: 384px;
  padding: 24px;
  box-sizing: border-box;
  border-radius: 10px;
}

.pay_subscription_modal .vfm--overlay{
  position: fixed!important;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}
@media (max-width: 992px) {
  .pay_subscription_modal_content {
    width: 100%;
  }
}
</style>
<style scoped lang='scss'>

.mt-16 {
  margin-top: 16px;
}

.header_modal {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.welcome {
  margin-top: 60px;
  background: radial-gradient(50.46% 72659998.73% at 49.54% 100.88%, #ffffff 0, rgba(4, 235, 159, 0) 100%);
  height: 1px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text_welcome {
  color: #FF4550;
  position: absolute;
  top: -10px;
  background: #121314;
  padding: 0 10px;
  text-transform: uppercase;
  font-size: 12px;
}

.text_title {
  color: white;
  font-size: 16px;
  margin-top: 20px;
  text-align: center;
}

.text {
  text-align: center;
  color: #959897;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.2px;
  margin-top: 10px;
}

.text_gray {
  color: #B4B8B6
}

.decoration {
  text-decoration: underline #B4B8B6;
}

.form_box{
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 40px;
}

.label_placeholder {
  color: #959897;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
}

.btns_sub {
  display: flex;
  align-items: center;
  justify-content: center;
}

.box{
  display: flex;
  justify-content: center;
}

</style>