<template>
  <VueFinalModal
    class="grow-with-modal"
    content-class="grow-with-modal-content"
    content-transition="vfm-fade"
  >
    <div class='header_modal'>
      <logo class='logo' />
      <svg @click="$emit('close')" width='1em' height='1em' viewBox='0 0 10 10' fill='none'
           xmlns='http://www.w3.org/2000/svg'
           class='cp text-xl text-violet-medium'>
        <path d='M8.5 1.5L1.5 8.5' stroke='#ffffff' stroke-width='2' stroke-linecap='round'
              stroke-linejoin='round'></path>
        <path d='M1.5 1.5L8.5 8.5' stroke='#ffffff' stroke-width='2' stroke-linecap='round'
              stroke-linejoin='round'></path>
      </svg>
    </div>
    <div  class='content_modal'>
       <div class='title_content'> Email Confirmation</div>
      <div class='text_content'>Please check your inbox and confirm your email in order to complete sign up process.</div>
    </div>
    <div class='btns_footer'>
      <button class='btn-red' @click="$emit('close')">
        Done
      </button>
    </div>

  </VueFinalModal>
</template>

<script>
import { VueFinalModal } from 'vue-final-modal'
import Logo from "@/components/svg/logo.vue";

export default {
  name: 'EmailConfirmationModal',
  components:{
    Logo,
    VueFinalModal
  }
};
</script>

<style >
.grow-with-modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.grow-with-modal-content {
  display: flex;
  flex-direction: column;
  padding: 30px;
  background: #1a1e1e;
  border-radius: 20px;
  width: 400px;
}
.grow-with-modal-content > * + *{
  margin: 0.5rem 0;
}
@media (max-width: 992px) {
  .grow-with-modal-content  {
    width: 100%;
  }
}
</style>
<style scoped lang='scss'>
.btns_footer{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}
.content_modal{
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
}

.header_modal{
  display: flex;
  justify-content: space-between;
}
.title_modal {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  color: white;
}
.title_content{
  color: #F3F6F4;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.6px;
}
.text_content{
  color: #B4B8B6;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.2px;
}
.btn-red{
  min-width: 150px;
}
</style>