<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M16 8.00386C16 12.411 12.4093 16 8 16C3.59073 16 0 12.411 0 8.00386C0 3.589 3.59073 0 8 0C12.4093 0 16 3.589 16 8.00386Z" fill="#E50914"/>
    <path d="M11.25 8.00395C11.25 8.2093 11.1875 8.41543 11.0625 8.5805C11.0391 8.61288 10.9297 8.74635 10.8437 8.83323L10.7969 8.88062C10.1406 9.60012 8.50781 10.6821 7.67969 11.0289C7.67969 11.0368 7.1875 11.2429 6.95312 11.25H6.92187C6.5625 11.25 6.22656 11.0454 6.05469 10.7137C5.96094 10.5313 5.875 10.0021 5.86719 9.99502C5.79687 9.52035 5.75 8.79374 5.75 7.99605C5.75 7.15966 5.79687 6.40067 5.88281 5.93469C5.88281 5.92679 5.96875 5.5003 6.02344 5.35814C6.10938 5.15358 6.26562 4.97904 6.46094 4.86847C6.61719 4.79028 6.78125 4.75 6.95312 4.75C7.13281 4.75869 7.46875 4.87637 7.60156 4.93165C8.47656 5.27916 10.1484 6.41646 10.7891 7.11148C10.8984 7.22205 11.0156 7.35711 11.0469 7.38791C11.1797 7.56166 11.25 7.77491 11.25 8.00395Z" fill="#222626"/>
  </svg>
</template>
<script>
export default{
  name: "playSvg"
};
</script>



<style scoped lang='scss'>

</style>