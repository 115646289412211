<template>
  <VueFinalModal
    class='forgot_password-modal'
    content-class='forgot_password-modal-content'
    content-transition='vfm-fade'
  >
    <div class='header_modal'>
      <div class='title_modal'></div>
      <img class='cp ' src='@/assets/icons/close_icon.svg' alt='ssl' @click="$emit('close')" />
    </div>
    <template v-if='!sent_email'>
      <div class='text_title'>Forgot password</div>
      <div class='content_modal'>
        <div class='text'>
          Enter your email address and press Reset Password.
          If you provided the correct email address associated with your account, you will receive an email with further
          instructions.
        </div>
        <div class='input-wrapper mt-16'>
          <svg class='input-icon' width='1em' height='1em' viewBox='0 0 16 16' fill='none'
               xmlns='http://www.w3.org/2000/svg'>
            <path opacity='0.9'
                  d='M15 11.1828C15 13.0119 13.432 14.4934 11.479 14.5H11.472H4.535C2.589 14.5 1 13.025 1 11.1959V11.1894C1 11.1894 1.0042 8.28782 1.0098 6.82852C1.0105 6.55449 1.3465 6.40109 1.5754 6.57154C3.2386 7.80729 6.2129 10.0605 6.25 10.09C6.747 10.463 7.377 10.6734 8.021 10.6734C8.665 10.6734 9.295 10.463 9.792 10.0828C9.8291 10.0598 12.7369 7.87416 14.4253 6.61808C14.6549 6.44698 14.9923 6.60038 14.993 6.87376C15 8.32191 15 11.1828 15 11.1828Z'
                  fill='#666'></path>
            <path
              d='M14.4312 3.31376C13.841 2.20678 12.6796 1.5 11.401 1.5H4.59902C3.32041 1.5 2.15904 2.20678 1.56881 3.31376C1.43658 3.56134 1.49929 3.86996 1.71943 4.04496L6.78001 8.07335C7.13442 8.35824 7.5638 8.5 7.99318 8.5C7.99591 8.5 7.99796 8.5 8 8.5C8.00204 8.5 8.00477 8.5 8.00682 8.5C8.4362 8.5 8.86558 8.35824 9.21999 8.07335L14.2806 4.04496C14.5007 3.86996 14.5634 3.56134 14.4312 3.31376Z'
              fill='#666'>
            </path>
          </svg>
          <input id='email' v-model='email' class='input-style' placeholder=' ' type='email'>
          <label for='email' class='label_placeholder'>email address</label>
          <label v-if='valid_email' for='' class='label_valid'>{{ valid_email }}</label>
        </div>
      </div>
      <div class='btns_footer mt-16'>
        <button class='btn-red' @click='resetPassword()'>
          Reset password
        </button>
      </div>
    </template>
    <template v-else>
      <div class='text_title'>
        An email has been sent
      </div>
      <div class='btns_footer mt-16'>
        <button class='btn-red' @click="$emit('close')">
          Done
        </button>
      </div>
    </template>
  </VueFinalModal>
</template>

<script>
import { VueFinalModal } from "vue-final-modal";
import { forgotPassword } from "@/services/api";
import { isInValidEmail } from "@/helpers/validate.helper";
import { mapMutations } from "vuex";
import { errorMessage } from "@/services/messages";

export default {
  name: "ForgotPasswordModal",
  components: {
    VueFinalModal
  },
  data() {
    return {
      email: "",
      valid_email: "",
      sent_email: false
    };
  },

  methods: {
    ...mapMutations([
      "showLoader",
      "hideLoader"
    ]),
    async resetPassword() {
      this.valid_email = "";
      let valid_form = true;
      if (!this.email) {
        this.valid_email = "This field is required";
        valid_form = false;
      } else if (isInValidEmail(this.email)) {
        this.valid_email = "Please check this field";
        valid_form = false;
      }
      if (!valid_form) {
        return;
      }
      this.showLoader();
      try {
        await forgotPassword({ email: this.email });
      } catch (e) {
        if (e?.response?.data?.error?.message) {
          this.valid_email = e?.response?.data?.error?.message;
        } else {
          errorMessage("ERROR");
        }
        return;
      }
      this.hideLoader();
      this.sent_email = true;

    }

  }
};
</script>

<style scoped lang='scss'>
.btn-red {
  min-width: 100px;
}

.input-style {
  width: 100%;
  height: 48px;
  padding-left: 30px;
}

.input-wrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
}

.input-icon {
  position: absolute;
  left: 10px;
  fill: #666;
  top: 15px;
}

.mt-16 {
  margin-top: 16px;
}

.header_modal {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.text_title {
  color: white;
  font-size: 14px;
  margin-top: 20px;
  text-align: center;
}

.text {
  text-align: center;
  color: #959897;
  font-size: 12px;
  margin-top: 10px;
}

.sharing_your_mail {
  color: #959897;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-top: 16px;
}

.header_modal {
  display: flex;
  justify-content: space-between;
}

.text_gray {
  color: #B4B8B6
}

.decoration {
  text-decoration: underline #B4B8B6;
}

.form_login {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 40px;
}

.toggle-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.label_placeholder {
  color: #959897;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
}

.input-style + .label_placeholder {
  position: absolute;
  top: 12px;
  left: 26px;
  transition: all 0.4s ease;
  pointer-events: none;
  padding: 0 5px;
}

.input-style:not(:placeholder-shown) + .label_placeholder,
.input-wrapper:focus-within .label_placeholder {
  top: -12px;
  background: #141616;
}

.btns_footer {
  display: flex;
  justify-content: center;
}
</style>
<style>
.forgot_password-modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.forgot_password-modal-content {
  max-width: 457px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px;
  background: #1a1e1e;
  border-radius: 20px;
}

.chapter-modal-content > * + * {
  margin: 0.5rem 0;
}

.input-style:not(:placeholder-shown) + .label_placeholder,
.input-wrapper:focus-within .label_placeholder {
  top: -12px;
  background: #141616;
}
</style>