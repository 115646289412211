<template>
  <div class='width_page'>
    <div class='header'>
      <div class='left_content'>
        <router-link to='/'>
          <Logo />
        </router-link>
        <div class='beta'>BETA</div>
        <separator-svg style='display: none' class='separator' v-if='isLoggedUser' />
        <div class='list_items' style='display: none' v-if='isLoggedUser'>
          <div class='item_header'>
            <explore />
            <span class='item_explore'>explore</span>
          </div>
          <div class='item_header'>
            <CategorySvg />
            <span class=''>categories</span>
            <down-svg class='down_svg' />
          </div>
          <div class='item_header'>
            <AboutSvg />
            <span>about us</span>
          </div>
        </div>
      </div>
      <div class='right_content'>
        <div v-if='!isLoggedUser' class='btn_create_login'>
          <button class='btn-red' @click='openModalRegistration'>create free account</button>
          <button class='btn-black' @click='openModalLogin'>Log in</button>
        </div>
        <template v-else>
          <div class='right_menu'>
            <div class='btn_icon'>
              <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
                <g clip-path='url(#clip0_12061_121)'>
                  <path fill-rule='evenodd' clip-rule='evenodd'
                        d='M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z'
                        fill='#7A8080' />
                  <path opacity='0.7'
                        d='M18.5 20C18.1372 19.9883 17.8526 19.7912 17.5946 19.5353L15.3827 16.9599C14.9085 16.528 14.8697 15.7938 15.2958 15.314C15.4952 15.113 15.7663 15 16.0492 15C16.332 15 16.6031 15.113 16.8025 15.314L19.5843 17.5312C19.985 17.9397 20.1086 18.5462 19.9 19.0795C19.6913 19.6129 19.1893 19.9734 18.6184 20H18.5Z'
                        fill='#7A8080' />
                  <path opacity='0.7' fill-rule='evenodd' clip-rule='evenodd'
                        d='M10 8C10 6.89543 9.10457 6 8 6V4C10.2091 4 12 5.79086 12 8H10Z' fill='#7A8080' />
                </g>
                <defs>
                  <clipPath id='clip0_12061_121'>
                    <rect width='20' height='20' fill='white' />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <router-link to='/favorite-courses' class='btn_icon'>
              <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
                <path fill-rule='evenodd' clip-rule='evenodd'
                      d='M1.35235 9.12139C0.335666 5.95721 1.52385 2.3406 4.85625 1.27044C6.60915 0.706554 8.54397 1.03903 10.0012 2.13185C11.3799 1.06925 13.3858 0.710332 15.1368 1.27044C18.4692 2.3406 19.6649 5.95721 18.6492 9.12139C17.0668 14.1369 10.0012 18 10.0012 18C10.0012 18 2.98775 14.1954 1.35235 9.12139Z'
                      stroke='#7A8080' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' />
                <path opacity='0.7' fill-rule='evenodd' clip-rule='evenodd'
                      d='M14 8C14 6.89543 13.1046 6 12 6V4C14.2091 4 16 5.79086 16 8H14Z' fill='#7A8080' />
              </svg>
            </router-link>
            <!--          <div class='btn_icon'>-->
            <!--            <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>-->
            <!--              <path opacity='0.7' fill-rule='evenodd' clip-rule='evenodd'-->
            <!--                    d='M12.1834 11.135C12.4793 11.0265 12.7971 10.9846 13.1062 11.005C13.4149 11.0254 13.7277 11.109 14.0092 11.2638L19.1001 14.0638C19.3357 14.1933 19.5582 14.3783 19.7255 14.6236C19.8951 14.8725 20 15.1736 20 15.5C20 15.8264 19.8951 16.1275 19.7255 16.3764C19.5582 16.6217 19.3357 16.8066 19.1001 16.9362L14.0092 19.7362C13.7277 19.891 13.4149 19.9746 13.1062 19.995C12.7971 20.0154 12.4793 19.9735 12.1834 19.865C11.8891 19.7571 11.6008 19.5769 11.3779 19.3124C11.151 19.0432 11 18.6927 11 18.3V12.7C11 12.3073 11.151 11.9568 11.3779 11.6875C11.6008 11.4231 11.8891 11.2429 12.1834 11.135ZM13 13.0035V17.9965C13.0097 17.9949 13.0183 17.993 13.0257 17.9909C13.0387 17.9872 13.045 17.984 13.0453 17.9838L13.0454 17.9838L17.5613 15.5L13.0454 13.0162C13.0454 13.0162 13.0454 13.0162 13.0453 13.0162C13.045 13.016 13.0387 13.0128 13.0257 13.0091C13.0183 13.007 13.0097 13.0051 13 13.0035Z'-->
            <!--                    fill='#7A8080' />-->
            <!--              <path fill-rule='evenodd' clip-rule='evenodd'-->
            <!--                    d='M2 7V17C2 17.5523 2.44772 18 3 18H8C8.55228 18 9 18.4477 9 19C9 19.5523 8.55228 20 8 20H3C1.34315 20 0 18.6569 0 17V7H2Z'-->
            <!--                    fill='#7A8080' />-->
            <!--              <path opacity='0.7' fill-rule='evenodd' clip-rule='evenodd'-->
            <!--                    d='M6.24253 0.0298499C6.77832 0.163799 7.10408 0.706733 6.97013 1.24253L5.97013 5.24253C5.83619 5.77832 5.29325 6.10408 4.75746 5.97013C4.22166 5.83619 3.8959 5.29325 4.02985 4.75746L5.02985 0.757457C5.1638 0.221662 5.70673 -0.104099 6.24253 0.0298499Z'-->
            <!--                    fill='#7A8080' />-->
            <!--              <path opacity='0.7' fill-rule='evenodd' clip-rule='evenodd'-->
            <!--                    d='M15.2425 0.0298499C15.7783 0.163799 16.1041 0.706733 15.9701 1.24253L14.9701 5.24253C14.8362 5.77832 14.2933 6.10408 13.7575 5.97013C13.2217 5.83619 12.8959 5.29325 13.0298 4.75746L14.0298 0.757457C14.1638 0.221662 14.7067 -0.104099 15.2425 0.0298499Z'-->
            <!--                    fill='#7A8080' />-->
            <!--              <path fill-rule='evenodd' clip-rule='evenodd'-->
            <!--                    d='M20 7V10C20 10.5523 19.5523 11 19 11C18.4477 11 18 10.5523 18 10V7H20Z' fill='#7A8080' />-->
            <!--              <path fill-rule='evenodd' clip-rule='evenodd'-->
            <!--                    d='M18 2H2V4H18V2ZM2 0C0.895431 0 0 0.89543 0 2V5C0 5.55228 0.447716 6 1 6H19C19.5523 6 20 5.55228 20 5V2C20 0.895431 19.1046 0 18 0H2Z'-->
            <!--                    fill='#7A8080' />-->
            <!--            </svg>-->
            <!--          </div>-->
          </div>
          <div class='user_box_menu'>
            <div class='nav-link' @click.stop='toggleDropdown'>
              <div class='box_avatar' :class='{box_avatar_standard:getWordUserPlan==="STA",box_avatar_pre:getWordUserPlan==="PRE",box_avatar_free:getWordUserPlan===""}'>
                <div class='plan_user' :class='{plan_premium:getWordUserPlan==="PRE",plan_standard:getWordUserPlan==="STA"}' v-if='getWordUserPlan'>{{getWordUserPlan}}</div>
                <img v-if='get_img_url' class='avatar_header' :src='get_img_url' alt='avatar' />
                <img v-else class='avatar_header' src='@/assets/img/default-avatar.png' alt='avatar' />
              </div>
              <down-svg class='down_svg' :color='colorPlan' :class='{up_svg_down:dropdownOpen}' />
            </div>
            <ul v-if='dropdownOpen' class='user-prof-menu'>
              <li>
                <router-link to='/profile/edit'>Profile</router-link>
              </li>
              <li v-if='getLoggedUser?.role==="admin"'>
                <router-link to='/admin/courses'>Courses/Shows</router-link>
              </li>
              <li>
                <hr>
              </li>
              <li><a href='#' @click='logout'>Logout</a></li>
            </ul>
          </div>
        </template>
      </div>
      <div @click='toggleMenu()' class='right_content_mobile'>
        <svg class='cp' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
          <path d='M22.5 12.5H1.5' stroke='#7A8080' stroke-width='2' stroke-miterlimit='10' stroke-linecap='round' />
          <path opacity='0.7' d='M22.5 4.5L1.5 4.5' stroke='#7A8080' stroke-width='2' stroke-miterlimit='10'
                stroke-linecap='round' />
          <path opacity='0.7' d='M22.5 20.5H1.5' stroke='#7A8080' stroke-width='2' stroke-miterlimit='10'
                stroke-linecap='round' />
        </svg>
      </div>
    </div>
    <div class='mob_menu' :class="{ 'mob_menu--open': isMenuOpen }">
      <ul v-if='isLoggedUser' class='mob-prof-menu'>
        <li>
          <router-link to='/profile/edit'>Profile</router-link>
        </li>
        <li v-if='getLoggedUser?.role==="admin"'>
          <router-link to='/admin/courses'>Courses/Shows</router-link>
        </li>
        <li><a href='#' @click='logout'>Logout</a></li>
      </ul>
      <ul v-else class='mob-prof-menu'>
        <li><a href='#' @click='openModalRegistration'>Create account</a></li>
        <li><a href='#' @click='openModalLogin'>Log in</a></li>
      </ul>
    </div>
  </div>
  <modals-container-registration-login ref='modalsRL' />
</template>

<script>

import Logo from "@/components/svg/logo.vue";
import Explore from "@/components/svg/explore.vue";
import CategorySvg from "@/components/svg/categorySvg.vue";
import AboutSvg from "@/components/svg/aboutSvg.vue";
import DownSvg from "@/components/svg/downSvg.vue";
import SeparatorSvg from "@/components/svg/separatorSvg.vue";
import { mapGetters, mapMutations } from "vuex";
import http from "@/services/http_client";
import ModalsContainerRegistrationLogin from "@/components/ModalsContfinerRegistrationLogin.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Header",
  components: { ModalsContainerRegistrationLogin, SeparatorSvg, DownSvg, AboutSvg, CategorySvg, Explore, Logo },
  data() {
    return {
      dropdownOpen: false,
      isMenuOpen: false
    };
  },
  mounted() {
    window.addEventListener("click", this.closeDropdown);
  },
  beforeUnmount() {
    window.removeEventListener("click", this.closeDropdown);
  },
  methods: {
    ...mapMutations([
      'setLoggedUser',
    ]),
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    openModalRegistration() {
      this.$refs.modalsRL.openModalRegistration();
    },
    openModalLogin() {
      this.$refs.modalsRL.openModalLogin();
    },
    closeDropdown(e) {
      if (!this.$el.contains(e.target)) {
        this.dropdownOpen = false;
      }
    },
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },

    async logout() {
      delete localStorage.panagenius_user;
      delete localStorage.panagenius_jwt;
      await this.$router.push("/");
      window.location.reload();
      //await this.$router.replace(this.$route.path)
    },
    handleGlobalClick(e) {
      if (!this.$el.contains(e.target)) {
        this.dropdownOpen = false;
      }
    }
  },
  computed: {
    ...mapGetters([
      "getLoggedUser"
    ]),
    getWordUserPlan(){
      if (this.getLoggedUser?.active_plan){
        switch (this.getLoggedUser?.active_plan) {
          case "premium": {
            return "PRE"
          }
          case "standard": {
            return "STA"
          }
          case "free": {
            return ""
          }
        }
      }
      return ''
    },
    colorPlan( ){
      switch (this.getWordUserPlan) {
        case "PRE": {
          return "#B3A100"
        }
        case "STA": {
          return "#4485f7"
        }
      }
      return "#f4f6f4"
    },
    get_img_url() {
      if (this.getLoggedUser?.avatar) {
        return http.uploadsUrl() + this.getLoggedUser?.avatar;
      }
      return null;
    },
    isLoggedUser() {
      return !!this.getLoggedUser?.id;
    }
  }
};
</script>

<style lang='scss' scoped>
.header {
  background: #131616;
  padding-top: 25px;
  padding-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #272b2b;
}

.left_content {
  display: flex;
  align-items: center;
}

.separator {
  margin-left: 36px;
  margin-right: 24px;
}

.right_content {
  display: flex;
  gap: 16px;
  align-items: center;
}

.right_content_mobile {
  display: none;
}

.list_items {
  display: flex;
  gap: 40px;
}

.item_header {
  gap: 8px;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;

  & span {
    color: #959897;
  }

  & .item_explore {
    color: #F3F6F4;
  }
}

.down_svg {
  padding-bottom: 2px;
}

.btn_create_login {
  display: flex;
  gap: 16px;
  align-items: center;
}

.btn_icon {
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
}

.btn_icon:hover {
  border-radius: 12px;
  border: 2px solid #222626;

  svg path {
    fill: #B4B8B6;
  }
}

.right_menu {
  display: flex;
}

.box_avatar {
  position: relative;
  padding: 4px;
  width: 24px;
  height: 24px;
}

.box_avatar_pre:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px;
  border: 2px solid transparent;
  background: linear-gradient(120deg, #B3A100, #222626) border-box;
  -webkit-mask: linear-gradient(#222626 0 0) padding-box, linear-gradient(#222626 0 0);
  mask-composite: exclude;
}
.box_avatar_standard:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px;

  border: 2px solid transparent;
  background: linear-gradient(120deg, #4485f7, #222626) border-box;
  -webkit-mask: linear-gradient(#222626 0 0) padding-box, linear-gradient(#222626 0 0);
  mask-composite: exclude;
}
.box_avatar_free:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px;

  border: 2px solid transparent;
  background: linear-gradient(120deg, #f4f6f4, #222626) border-box;
  -webkit-mask: linear-gradient(#222626 0 0) padding-box, linear-gradient(#222626 0 0);
  mask-composite: exclude;
}
.avatar_header {
  width: 24px;
  height: 24px;
  border-radius: 7px;
  object-fit: cover;
}

.user-prof-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  top: 40px;
  left: -170px;
  border: 1px solid #2F3737;
  background: #222626;
  position: absolute;
  z-index: 4;
  border-radius: 4px;
  min-width: 200px;
}

.user_box_menu {
  position: relative;
}

.user-prof-menu li a {
  display: block;
  padding: 12px 16px;
  text-decoration: none;
  color: #959897;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: end;
}

.user-prof-menu li a:hover {
  background-color: rgba(49, 49, 49, 0.76);
  color: #e3e3e3;
}

.user-prof-menu li hr {
  margin: 4px 0;
  border-color: #111111;
}

.nav-link {
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
}

.up_svg_down {
  transform: rotate(180deg);
}

.left_content a {
  display: flex;
}

.mob_menu {
  position: fixed;
  top: -100%;
  left: 0;
  right: 0;
  transition: top 0.5s ease;
}

.mob_menu--open {
  top: 80px;
  z-index: 3;
}

.mob-prof-menu {
  z-index: 4;
  list-style: none;
  padding: 0;
  margin: 0;
  background: #141616;
  width: 100%;
}

.mob-prof-menu li a {
  display: block;
  padding: 12px 16px;
  text-decoration: none;
  color: #959897;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
}

.beta {
  margin-left: 20px;
  background: #b4b8b6;
  height: 26px;
  border-radius: 24px;
  font-size: 13px;
  font-weight: 900;
  padding: 1px 16px 0 16px;
  display: flex;
  align-items: center;
}
.plan_user{
  font-family: 'Axiforma Black',sans-serif;
  position: absolute;
  font-weight: 900;
  font-size: 6px;
  line-height: 11px;
  width: 20px;
  height: 10px;
  display: inline-block;
  text-align: center;
  border: 1px solid rgba(19, 22, 22, 1);
  border-radius: 8px;
  right: 0;
  bottom: 0;
  z-index: 5;
}
.plan_premium{
  background: #f0d801;
}
.plan_standard{
 background: rgba(67, 133, 247, 1);
}
@media (max-width: 992px) {
  .right_content {
    display: none;
  }
  .right_content_mobile {
    display: flex;
  }
  .list_items {
    display: none;
  }
}

</style>
