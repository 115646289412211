<template>
  <svg width="100%" height="1" viewBox="0 0 1200 1" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="100%" height="1" fill="url(#paint0_radial_11896_1871)"/>
    <defs>
      <radialGradient id="paint0_radial_11896_1871" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(594.5 1.00881) scale(605.5 726600)">
        <stop stop-color="#BABABA"/>
        <stop offset="1" stop-color="#E6E6E6" stop-opacity="0"/>
      </radialGradient>
    </defs>
  </svg>
</template>
<script>
export default{
  name: "separatorLong"
};
</script>



<style scoped lang='scss'>

</style>