<template>
  <div class='carousel_container' @mouseover="pauseAnimation"
       @mouseleave="resumeAnimation">
    <div class='gradient_left'></div>
    <div class='carousel_videos' ref='carousel'>
      <div class='item_video' v-for="(img, index) in duplicatedImages" :key="index">
        <img class='img_carousel' :src='getImg(img)' alt=''>
      </div>
    </div>
    <div class='gradient_right'></div>
  </div>
</template>

<script>
export default {
  name: "VideoCarousel",
  props: {
    scrollDirection:{
      default: 'left',
    },
    images: {
      type: Array,
      default: () => [
        'IMG_6132.jpg',
        'IMG_6135.jpg',
        'IMG_6161.png',
        'IMG_6137.jpg',
        'IMG_6365.jpg',
        'IMG_6139.jpg',
        'IMG_6140.jpg',
      ]
    }
  },
  data() {
    return {

    };
  },
  mounted() {
    this.applyAnimation();
  },

  computed: {
    duplicatedImages() {
      return this.images.concat(this.images);
    }
  },

  methods: {
    getImg(image) {
      return require(`@/assets/img/${image}`);
    },
    applyAnimation() {
      const animationName = this.scrollDirection === 'left' ? 'scrollLeft' : 'scrollRight';
      this.$refs.carousel.style.animation = `${animationName} 100s linear infinite`;
    },
    pauseAnimation() {
      this.$refs.carousel.style.animationPlayState = 'paused';
    },
    resumeAnimation() {
      this.$refs.carousel.style.animationPlayState = 'running';
    },
  }
};
</script>
<style>

@keyframes scrollLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

@keyframes scrollRight {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
<style scoped lang="scss">


.carousel_videos {
  display: flex;
  gap: 16px;
}

.item_video {
  flex: 0 0 auto;
}

.img_carousel {
  width: 282px;
  height: 158px;
  object-fit: cover;
  border-radius: 16px;
}

.carousel_container {
  overflow-x: hidden;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}

.gradient_left,
.gradient_right {
  position: absolute;
  width: 150px;
  height: 100%;
  z-index: 2;
}

.gradient_left {
  left: 0;
  background: linear-gradient(90deg, #121314, transparent);
}

.gradient_right {
  right: 0;
  background: linear-gradient(270deg, #121314, transparent);
}
.item_video .img_carousel {
  filter: grayscale(100%) brightness(80%);
}
.item_video .img_carousel:hover {
  filter: none;
}
</style>
