<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="2" height="16" viewBox="0 0 2 16" fill="none">
    <rect x="0.795914" width="1" height="16" rx="0.5" fill="#2B2E2E"/>
  </svg>
</template>
<script>
export default{
  name: "separatorSvg"
};
</script>
<style scoped lang='scss'>

</style>