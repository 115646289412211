<template>
  <VueFinalModal
    class='subscription_modal'
    content-class='subscription_modal_content'
    content-transition='vfm-fade'
  >
    <div class='header_modal'>
      <logo class='logo' />
      <svg @click="$emit('close')" width='1em' height='1em' viewBox='0 0 10 10' fill='none'
           xmlns='http://www.w3.org/2000/svg'
           class='cp text-xl text-violet-medium'>
        <path d='M8.5 1.5L1.5 8.5' stroke='#ffffff' stroke-width='2' stroke-linecap='round'
              stroke-linejoin='round'></path>
        <path d='M1.5 1.5L8.5 8.5' stroke='#ffffff' stroke-width='2' stroke-linecap='round'
              stroke-linejoin='round'></path>
      </svg>
    </div>
    <div>
      <div class='welcome'>
        <span class='text_welcome'>optional</span>
      </div>
      <div class='text_title'>Our Voices Matter! Now,<br /> More than Ever!</div>
      <div class='text'>Help us amplify our stories and bring you more premium content relevant to you and our
        communities.
      </div>
      <div class='form_login'>
        <div class='btns_sub'>
          <div class='btn_check_sub' @click='selectSub("monthly")' :class='{active_sub_annual:active_sub === "monthly"}'>
             Monthly
          </div>
          <div class='btn_check_sub' @click='selectSub("annual")'
               :class='{active_sub_montly:active_sub === "annual"}'>
            <span class='sale'>15% off</span>
            Annual
          </div>
        </div>
        <div class='items_sub'>
          <div class='box_sub' :class='{item_sub_active:sub_val===sub.name,disabled_sud:isPlanUser===sub.name}' @click='selectSubValue(sub.name)'
               v-for='sub in plans' :key='sub.name'>
            <div class='item_sub'>
              <div class='value_sub'>
                <svg v-if='sub_val!==sub.name' xmlns='http://www.w3.org/2000/svg' width='20' height='20'
                     viewBox='0 0 20 20' fill='none'>
                  <g clip-path='url(#clip0_12258_9797)'>
                    <rect width='20' height='20' fill='#131616' />
                    <path
                      d='M1 5C1 2.79086 2.79086 1 5 1H15C17.2091 1 19 2.79086 19 5V15C19 17.2091 17.2091 19 15 19H5C2.79086 19 1 17.2091 1 15V5Z'
                      fill='#131616' stroke='#2F3737' stroke-width='2' />
                  </g>
                  <defs>
                    <clipPath id='clip0_12258_9797'>
                      <rect width='20' height='20' fill='white' />
                    </clipPath>
                  </defs>
                </svg>
                <svg v-else xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
                  <g clip-path='url(#clip0_12258_9792)'>
                    <path fill-rule='evenodd' clip-rule='evenodd'
                          d='M5 0C2.23858 0 0 2.23858 0 5V15C0 17.7614 2.23858 20 5 20H15C17.7614 20 20 17.7614 20 15V5C20 2.23858 17.7614 0 15 0H5ZM8.16803 12.9918C8.34154 13.1628 8.56882 13.2478 8.7961 13.2478C9.02339 13.2478 9.25067 13.1628 9.42418 12.9918L14.2397 8.24583C14.5867 7.90383 14.5867 7.34983 14.2397 7.00783C13.8927 6.66583 13.3316 6.66583 12.9846 7.00783L8.7961 11.1358L7.01538 9.38083C6.66837 9.03883 6.10726 9.03983 5.76025 9.38183C5.41324 9.72283 5.41324 10.2768 5.76025 10.6188L8.16803 12.9918Z'
                          fill='#E50914' />
                  </g>
                  <defs>
                    <clipPath id='clip0_12258_9792'>
                      <rect width='20' height='20' fill='white' />
                    </clipPath>
                  </defs>
                </svg>
                {{ sub.title }}

              </div>
              <div class='price_sub'>${{ active_sub === "annual" ? sub.price_yearly : sub.price_monthly }}</div>
            </div>
            <div v-if='sub?.description' class='sub_desc'>{{ sub?.description }}</div>
          </div>
        </div>
      </div>
      <div class='btn-red mt-16' @click='confirmSub()' :class='{disabled_sud:!sub_val}'>Confirm</div>
      <div class='box' style='display: none'>
        <div class='skip' @click="$emit('skip')">
          SKIP
          <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
            <path
              d='M7.80964 8.75517L12.375 5.94236C12.4773 5.87969 12.6082 5.88569 12.7039 5.9597C13.1377 6.29371 13.5252 6.6324 13.7837 6.91708C13.7837 6.91708 14.0065 7.14509 14.1029 7.28976C14.2585 7.48711 14.3334 7.74512 14.3334 7.9958C14.3334 8.27715 14.2514 8.54316 14.0879 8.75584C14.0508 8.79384 13.9094 8.96118 13.7759 9.09785C12.997 9.94856 10.9637 11.354 9.89435 11.78C9.7387 11.8487 9.32319 11.992 9.10762 12C8.89986 12 8.69927 11.9547 8.5065 11.8553C8.26943 11.7187 8.08382 11.5066 7.97962 11.2553C7.91254 11.0806 7.80899 10.5566 7.80899 10.5413C7.74191 10.1672 7.69176 9.64188 7.66701 9.02852C7.66245 8.91851 7.71781 8.81184 7.80964 8.75517Z'
              fill='#B4B8B6' />
            <path opacity='0.4'
                  d='M8.11489 7.24018C7.91365 7.36486 7.6603 7.20552 7.67007 6.96617C7.69417 6.40481 7.73585 5.91012 7.78665 5.5421C7.79447 5.5341 7.89802 4.8814 8.0172 4.66072C8.22496 4.2507 8.63265 4.00002 9.07096 4.00002H9.10743C9.39008 4.00736 9.9912 4.25803 9.9912 4.27337C10.294 4.39938 10.6894 4.61272 11.1146 4.8694C11.3061 4.9854 11.3107 5.27009 11.1192 5.38809L8.11489 7.24018Z'
                  fill='#B4B8B6' />
            <path opacity='0.4'
                  d='M5.46832 8.79362L3.00217 9.01172C2.44872 9.01172 2 8.55863 2 7.99979C2 7.44095 2.44872 6.98785 3.00217 6.98785L5.46832 7.20595C5.9025 7.20595 6.25449 7.56138 6.25449 7.99979C6.25449 8.43893 5.9025 8.79362 5.46832 8.79362Z'
                  fill='#B4B8B6' />
          </svg>
        </div>
      </div>
    </div>
  </VueFinalModal>
</template>

<script>
import { VueFinalModal } from "vue-final-modal";
import Logo from "@/components/svg/logo.vue";
import { getUserPlan } from "@/services/api";
import { mapGetters } from "vuex";

export default {
  name: "SubscriptionModal",
  components: {
    Logo,
    VueFinalModal
  },
  data() {
    return {
      active_sub: "monthly",
      sub_val: null,
      user_active_plan: "free",
    };
  },
  mounted() {
    this.getUserActivePlan()
  },
  methods: {
    async getUserActivePlan() {
      const { user_plan } = await getUserPlan();
      this.user_active_plan = user_plan || "free";
    },
    confirmSub() {
      if (!this.sub_val){
        return
      }
      this.$emit("confirm", { payment_period: this.active_sub, sub_val: this.sub_val });
    },
    selectSub(val) {
      this.active_sub = val;
    },
    selectSubValue(name) {
      this.sub_val = name;
    }
  },
  computed: {
    ...mapGetters([
      "getLoggedUser"
    ]),
    isPlanUser(){
      if (this.getLoggedUser?.confirmed){
        return this.user_active_plan ;
      }
      return null
    },
    plans() {
      return [
        {
          title: "Free (Access limited content)",
          name: "free",
          description: "",
          price_monthly: 0,
          price_yearly: 0
        },
        {
          name: "standard",
          title: "Standard (Access all content)",
          description: "",
          price_monthly: 5.99,
          price_yearly: 61.09
        },
        {
          name: "premium",
          title: "Premium (VIP Member)",
          description: "Standard + discounts on PanaGenius events and other perks",
          price_monthly: 15.99,
          price_yearly: 163.09
        }
      ];
    }
  }
};
</script>

<style>
.subscription_modal {
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.subscription_modal_content {
  display: flex;
  flex-direction: column;
  background: #121314;
  width: 384px;
  padding: 24px;
  box-sizing: border-box;
  border-radius: 10px;
}

.btn_check_sub {
  color: #959897;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
  border-radius: 8px;
  border: 2px solid #2F3737;
  padding: 12px 16px;
  cursor: pointer;
  position: relative;
}

.subscription_modal .vfm--overlay {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

@media (max-width: 992px) {
  .subscription_modal_content {
    width: 100%;
  }
}
</style>
<style scoped lang='scss'>

.mt-16 {
  margin-top: 16px;
}

.header_modal {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.welcome {
  margin-top: 60px;
  background: radial-gradient(50.46% 72659998.73% at 49.54% 100.88%, #ffffff 0, rgba(4, 235, 159, 0) 100%);
  height: 1px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text_welcome {
  color: #FF4550;
  position: absolute;
  top: -10px;
  background: #121314;
  padding: 0 10px;
  text-transform: uppercase;
  font-size: 12px;
}

.text_title {
  color: white;
  font-size: 16px;
  margin-top: 20px;
  text-align: center;
}

.text {
  text-align: center;
  color: #959897;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.2px;
  margin-top: 10px;
}

.text_gray {
  color: #B4B8B6
}

.decoration {
  text-decoration: underline #B4B8B6;
}

.form_login {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 40px;
}

.label_placeholder {
  color: #959897;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
}

.btns_sub {
  display: flex;
  align-items: center;
  justify-content: center;
}

.active_sub_montly {
  position: relative;
  left: -2px;
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
}

.box {
  display: flex;
  justify-content: center;
}

.skip {
  margin: 11px;
  display: flex;
  gap: 4px;
  align-items: center;
  color: #B4B8B6;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  cursor: pointer;
  text-align: center;
}

.skip:hover {
  color: #838584;
}

.active_sub_annual {
  position: relative;
  right: -2px;
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
}

.active_sub_annual:after {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 8px;
  border: 2px solid transparent;
  background: linear-gradient(120deg, #d01515, #222626) border-box;
  -webkit-mask: linear-gradient(#222626 0 0) padding-box, linear-gradient(#222626 0 0);
  mask-composite: exclude;
}

.active_sub_montly {
  right: 2px;
}

.active_sub_montly:after {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 8px;
  border: 2px solid transparent;
  background: linear-gradient(120deg, #d01515, #222626) border-box;
  -webkit-mask: linear-gradient(#222626 0 0) padding-box, linear-gradient(#222626 0 0);
  mask-composite: exclude;
}

.items_sub {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.box_sub {
  position: relative;
  padding: 12px 16px;
  cursor: pointer;
  border-radius: 8px;
  border: 2px solid #2F3737;
}

.item_sub {
  display: flex;
  justify-content: space-between;
}

.sub_desc {
  color: #969897;
  font-size: 10px;
  padding-top: 9px;
}

.value_sub {
  color: #959897;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.price_sub {
  color: #B4B8B6;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.2px;
  display: flex;
  align-items: center;
}

.item_sub_active:after {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border-radius: 8px;
  border: 2px solid transparent;
  background: linear-gradient(120deg, #d01515, #222626) border-box;
  -webkit-mask: linear-gradient(#222626 0 0) padding-box, linear-gradient(#222626 0 0);
  mask-composite: exclude;
}
.sale{
  position: absolute;
  top: -3px;
  font-size: 8.7px;
  color: #E50914;
  right: 7px;
}
.disabled_sud{
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}
</style>