<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M16 8.00386C16 12.411 12.4093 16 8 16C3.59073 16 0 12.411 0 8.00386C0 3.589 3.59073 0 8 0C12.4093 0 16 3.589 16 8.00386Z" fill="#F3F6F4"/>
    <path d="M7.99983 5.88115C8.56393 5.88115 9.00064 5.4616 9.00064 4.88699C9.00064 4.3215 8.56393 3.89282 7.99983 3.89282C7.44484 3.89282 6.99902 4.3215 6.99902 4.88699C6.99902 5.4616 7.43574 5.88115 7.99983 5.88115ZM8.40056 12.1067C8.6767 12.1067 8.90056 11.8829 8.90056 11.6067V7.48735C8.90056 7.21121 8.67671 6.98735 8.40056 6.98735H7.6082C7.33206 6.98735 7.1082 7.21121 7.1082 7.48735V11.6067C7.1082 11.8829 7.33206 12.1067 7.6082 12.1067H8.40056Z" fill="#222626"/>
  </svg>
</template>
<script>
export default {
  name: "InfoSvg"
};
</script>

<style scoped lang='scss'>

</style>