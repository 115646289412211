<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 16" fill="none">
    <path d="M7.88846 4.39894L7.88846 4.39894L7.88877 4.40194L7.91887 4.69682L7.92047 4.71139C7.96973 5.1327 8 6.52113 8 7.99768C8 9.47379 7.96976 10.8647 7.92037 11.2902L7.91852 11.3074L7.88841 11.6131L7.8884 11.6131L7.88792 11.6182C7.85897 11.9287 7.83503 12.1717 7.77373 12.423C7.63553 12.9008 7.35589 13 7.24057 13C7.21801 13 7.21072 12.9999 7.20363 12.9997C7.19365 12.9994 7.18366 12.9993 7.17367 12.9993C7.17557 12.9993 7.17651 12.9993 7.17652 12.9992C7.17657 12.9987 7.13376 12.9939 7.05065 12.9535C6.96131 12.9101 6.8805 12.8519 6.83331 12.809L6.81778 12.7951L5.13276 11.3269C4.58651 10.8509 3.88645 10.5887 3.16191 10.5887H2.75569C2.49772 10.5887 2.16262 10.4305 2.08622 9.75683L2.08416 9.73991C1.96068 8.79506 1.98354 7.09467 2.08506 6.20748C2.16694 5.59936 2.52338 5.41132 2.75569 5.41132H3.15937C3.88621 5.41132 4.58833 5.14744 5.13526 4.66872L6.77664 3.23202L6.79048 3.21968C6.83849 3.17606 6.93294 3.11042 7.04691 3.05848C7.10059 3.03402 7.14743 3.01777 7.1836 3.0084C7.20927 3.00176 7.22296 3.00028 7.22539 3.00001C7.35484 3.00013 7.63828 3.11092 7.76848 3.55856C7.82846 3.80958 7.8561 4.07211 7.88846 4.39894Z" stroke="white" stroke-width="2" stroke-linejoin="round"/>
    <path d="M14 11.5C14.657 10.4286 15.0012 9.22421 15 8C15.0012 6.77579 14.657 5.5714 14 4.5" stroke="white" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
    <path d="M11 9.75C11.3269 9.23173 11.5009 8.62272 11.5 8C11.5009 7.37728 11.3269 6.76827 11 6.25" stroke="white" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
  </svg>
</template>
<script >

export default{
  name: "SoundSvg",
};
</script>

<style scoped lang='scss'>

</style>