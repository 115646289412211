<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="9" height="8" viewBox="0 0 9 8" fill="none">
    <g clip-path="url(#clip0_12061_712)">
      <path d="M8.7959 3L4.7959 5L0.795898 3" :stroke="color" stroke-width="2" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_12061_712">
        <rect width="8" height="8" fill="white" transform="translate(0.795898)"/>
      </clipPath>
    </defs>
  </svg>
</template>
<script >
export default {
  name: "downSvg",
  props:{
    color:{
      type:String,
      default:'#959897'
    }
  }
};
</script>

<style scoped lang='scss'>

</style>