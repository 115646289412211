<template>
  <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.89333 4.49749C5.89214 4.48546 5.89096 4.47361 5.8898 4.46194L4.79389 5.42118C4.06466 6.05948 3.12849 6.41132 2.15937 6.41132H2.06887C1.98389 7.25009 1.96898 8.76479 2.07295 9.58869H2.16191C3.12796 9.58869 4.06137 9.93831 4.78971 10.573L5.89152 11.533C5.89176 11.5305 5.892 11.5279 5.89224 11.5254L5.8932 11.515L5.92333 11.2094C5.92447 11.1979 5.9257 11.1864 5.92704 11.1749C5.96828 10.8196 6 9.49427 6 7.99768C6 6.50021 5.96822 5.17807 5.92723 4.82752L5.92404 4.79838L5.89333 4.49749ZM5.7979 12.1849C5.79789 12.1849 5.79815 12.1844 5.79872 12.1835L5.7979 12.1849ZM7.74043 12.6795C7.51632 13.4804 6.91892 14 6.24057 14C6.21916 14 6.19775 14 6.17367 13.9993C5.79904 13.9993 5.39163 13.7588 5.16083 13.5491L3.47581 12.0808C3.11164 11.7635 2.64494 11.5887 2.16191 11.5887H1.75569C0.863931 11.5887 0.211002 10.9138 0.0925911 9.8695C-0.043882 8.82524 -0.0171225 7.03376 0.0925911 6.0847C0.221705 5.09627 0.905409 4.41132 1.75569 4.41132H2.15937C2.64393 4.41132 3.11201 4.2354 3.47663 3.91625L5.11801 2.47955C5.38427 2.23764 5.85389 1.99858 6.22518 2.00001C6.89082 2.00001 7.51164 2.50103 7.73575 3.30408C7.81804 3.63976 7.85149 3.97615 7.8836 4.30038L7.9137 4.59526C8.02877 5.57939 8.02877 10.4142 7.9137 11.4055L7.8836 11.7111L7.88307 11.7168C7.85447 12.0235 7.82487 12.3409 7.74043 12.6795ZM2.09756 6.25666C2.09799 6.25581 2.09824 6.2554 2.09825 6.25541L2.09756 6.25666Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.4971 9.41421L14.29 11.2071L15.7042 9.79289L13.9113 8L15.7042 6.20711L14.29 4.79289L12.4971 6.58579L10.7042 4.79289L9.29001 6.20711L11.0829 8L9.29001 9.79289L10.7042 11.2071L12.4971 9.41421Z" fill="white"/>
  </svg>
</template>
<script>
export default {
  name: "muteSvg"
};
</script>

<style scoped lang='scss'>

</style>