import http from './http_client';
import { errorMessage } from '@/services/messages';
import storage from '@/store/index.js';


export const baseErrHandler = async (callback) => {
  try {
    storage.commit('showLoader');
    await callback();
  } catch (e) {
    console.log(e);
    errorMessage(e?.response?.data?.error?.message);
  } finally {
    storage.commit('hideLoader');
  }
};
export const registration = async (data) => {
  const response = await http.post(`${http.apiUrl()}users/registration`, data);
  const { user, access_token } = response?.data || {};
  return { user, access_token };
};
export const login = async (data) => {
  const response = await http.post(`${http.apiUrl()}users/login`, data);
  const { user, access_token, error } = response?.data || {};
  return { user, access_token, error };
};

export const createCourse = async (data) => {
  const response = await http.postAuth(`${http.apiUrl()}course/create`, data);
  const { course } = response?.data?.data || {};
  return { course };
};

export const updateCourse = async (data) => {
  const response = await http.postAuth(`${http.apiUrl()}course/update`, data);
  const { course } = response?.data?.data || {};
  return { course };
};
export const getCourseAdmin = async (id) => {
  const response = await http.getAuth(`${http.apiUrl()}course/show-admin/${id}`);
  const { course } = response?.data?.data || {};
  return { course };
};
export const getCourse = async (slug) => {
  const response = await http.getAuth(`${http.apiUrl()}course/show/${slug}`);
  const { course } = response?.data?.data || {};
  return { course };
};
export const getCourses = async () => {
  const response = await http.getAuth(`${http.apiUrl()}course/all`);
  const { courses } = response?.data?.data || {};
  return { courses };
};
export const getFilteredCourses = async (data) => {
  const response = await http.postAuth(`${http.apiUrl()}course/show-courses`,data);
  const { courses } = response?.data?.data || {};
  return { courses };
};

export const getCoursesCategory = async (data) => {
  const response = await http.post(`${http.apiUrl()}course/get-courses`,data);
  const { courses } = response?.data?.data || {};
  return { courses };
};
export const deleteCourse = async (id) => {
  const response = await http.getAuth(`${http.apiUrl()}course/delete/${id}`);
  const { success } = response?.data || {};
  return { success };
};

export const getMembers = async (data) => {
  const response = await http.postAuth(`${http.apiUrl()}users/members`,data);
  const { members, total, currentPage, pageSize } = response?.data?.data || [];
  return { members, total, currentPage, pageSize };
};

export const getMembersInfo = async () => {
  const response = await http.getAuth(`${http.apiUrl()}users/members-info`);
  const { totalUsers, standardPlanUsers, premiumPlanUsers, freePlanUsers } = response?.data?.data || [];
  return { totalUsers, standardPlanUsers, premiumPlanUsers, freePlanUsers };
};

export const deleteChapter = async (id) => {
  const response = await http.postAuth(`${http.apiUrl()}course/delete-chapter/${id}`);
  const { success } = response?.data?.data || {};
  return { success };
};

export const deleteLesson = async (id) => {
  const response = await http.postAuth(`${http.apiUrl()}course/delete-lesson/${id}`);
  const { success } = response?.data?.data || {};
  return { success };
};

export const updateOrderToChapters = async (formData) => {
  const response = await http.postAuth(`${http.apiUrl()}course/update-order/chapters`, formData);
  const { success } = response?.data?.data || {};
  return { success };
};

export const updateOrderToLessons = async (formData) => {
  const response = await http.postAuth(`${http.apiUrl()}course/update-order/lessons`, formData);
  const { success } = response?.data?.data || {};
  return { success };
};

export const updateOrderToCourse = async (data) => {
  const response = await http.postAuth(`${http.apiUrl()}course/update-order`, data);
  const { success } = response?.data?.data || {};
  return { success };
};

export const updateChapter = async (chapter_id, chapter_data) => {
  const response = await http.postAuth(`${http.apiUrl()}course/update-chapter/${chapter_id}`, chapter_data);
  const { chapter } = response?.data?.data || {};
  return { chapter };
};

export const createChapter = async (chapter_data) => {
  const response = await http.postAuth(`${http.apiUrl()}course/chapter-create/`, chapter_data);
  const { chapter } = response?.data?.data || {};

  return { chapter };
};

export const updateLesson = async (formData, id) => {
  const response = await http.postAuth(`${http.apiUrl()}course/lesson-edit/${id}`, formData);
  const { lesson } = response?.data?.data || {};
  return { lesson };
};

export const createLesson = async (formData) => {
  const response = await http.postAuth(`${http.apiUrl()}course/lesson-create/`, formData);
  const { lesson } = response?.data?.data || {};
  return { lesson };
};

export const getAuthUserBaseData = async () => {
  const response = await http.getAuth(`${http.apiUrl()}users/user`);
  const { user } = response?.data?.data || {};
  return user;
};

export const updateUser = async (data) => {
  const response = await http.postAuth(`${http.apiUrl()}users/update/`, data);
  const { user } = response?.data?.data || {};
  return { user };
};

export const updateUserProfile = async (data) => {
  const response = await http.postAuth(`${http.apiUrl()}users/update-profile/`, data);
  const { user } = response?.data?.data || {};
  return { user };
};
export const switchAccountType = async (data) => {
  const response = await http.postAuth(`${http.apiUrl()}users/switch-account`, data);
  const { user } = response?.data?.data || {};
  return { user };
};

export const updateUserAvatar = async (data) => {
  const response = await http.postAuth(`${http.apiUrl()}users/update-avatar/`, data);
  const { user } = response?.data?.data || {};
  return { user };
};

export const confirmEmailByToken = async (data) => {
  const response = await http.postAuth(`${http.apiUrl()}users/confirm-user-email-by-token`, data);
  const { user, access_token } = response?.data || {};
  return { user, access_token };
};

export const getCategories = async () => {
  const response = await http.get(`${http.apiUrl()}category/all`);
  const { categories } = response?.data?.data || {};
  return { categories };
};

export const paySubscription = async (data) => {
  const response = await http.postAuth(`${http.apiUrl()}payment/pay-subscription`, data);
  const { success } = response?.data?.data || {};
  return { success };
};

export const setFreeSubscription = async () => {
  const response = await http.postAuth(`${http.apiUrl()}subscription/create`);
  const { success } = response?.data?.data || {};
  return { success };
};

export const updatePassword = async (data) => {
  const response = await http.postAuth(`${http.apiUrl()}users/update-password`,data);
  const { success,error } = response?.data || {};
  return { success,error };
};

export const forgotPassword = async (data) => {
  const response = await http.post(`${http.apiUrl()}users/forgot-password`, data);
  const { success } = response?.data || {};
  return { success };
};
export const findUserByToken = async (data) => {
  const response = await http.post(`${http.apiUrl()}users/token-find-user`, { token: data });
  const { user } = response?.data?.data || {};
  return { user };
};
export const setNewPassword = async (data) => {
  const response = await http.post(`${http.apiUrl()}users/set-new-password`, data);
  const { user, access_token } = response?.data || {};
  return { user, access_token };
};
export const getUserPlan = async () => {
  const response = await http.getAuth(`${http.apiUrl()}users/active-plan`);
  const { user_plan } = response?.data?.data || {};
  return { user_plan };
};
export const inviteNewUser = async (data) => {
  const response = await http.postAuth(`${http.apiUrl()}users/invite-new`,data);
  const { success,error } = response?.data || {};
  return { success,error };
};

export const deleteUser = async (data) => {
  const response = await http.postAuth(`${http.apiUrl()}users/delete`,data);
  const { success,error } = response?.data || {};
  return { success,error };
};

export const getInvitationUsers = async (data) => {
  const response = await http.postAuth(`${http.apiUrl()}invitation/get-email-users`,data);
  const { members, total, currentPage, pageSize } = response?.data?.data || [];
  return { members, total, currentPage, pageSize };
};

export const likeCourse = async (data) => {
  const response = await http.postAuth(`${http.apiUrl()}like_course/like`,data);
  const { success,error } = response?.data || {};
  return { success,error };
};

export const getLikedCourses = async () => {
  const response = await http.getAuth(`${http.apiUrl()}like_course/show`);
  const { likedCourses } = response?.data?.data || [];
  return { likedCourses };
};

export const getPreferences = async () => {
  const response = await http.get(`${http.apiUrl()}preferences/all`);
  const { preferences } = response?.data?.data || [];
  return { preferences };
};

export const createJob = async (data) => {
  const response = await http.postAuth(`${http.apiUrl()}jobs/create`, data);
  const { job } = response?.data?.data || {};
  return { job };
};

export const saveUserJob = async (data) => {
  const response = await http.postAuth(`${http.apiUrl()}jobs/user-save`, data);
  const { is_saved } = response?.data?.data || {};
  return { is_saved };
};

export const savedUserJobsId = async () => {
  const response = await http.getAuth(`${http.apiUrl()}jobs/saved-ids`);
  const { jobs_id } = response?.data?.data || {};
  return { jobs_id };
};

export const updateUserData = async (data) => {
  const response = await http.postAuth(`${http.apiUrl()}users/update-data`, data);
  const { user } = response?.data?.data || {};
  return { user };
};

export const getUser = async () => {
  const response = await http.getAuth(`${http.apiUrl()}users/logged`);
  const { user } = response?.data?.data || {};
  return { user };
};

export const getUserById = async (id) => {
  const response = await http.getAuth(`${http.apiUrl()}users/profile/${id}`);
  const { user } = response?.data?.data || {};
  return { user };
};
export const searchAutocompleteAPI = async (query) => {
  const response = await http.getAuth(`${http.apiUrl()}search/autocomplete?query=${query}`);
  const { list } = response?.data?.data || {};
  return { list};
};
export const recentSearchesAutocompleteAPI = async () => {
  const response = await http.getAuth(`${http.apiUrl()}search/recent-searches`);
  const { recent_searches } = response?.data?.data || {};
  return { recent_searches };
};

export const saveRecentSearchesAPI = async (search) => {
  const response = await http.postAuth(`${http.apiUrl()}search/save-recent-searches`,search);
  const { recent_searches } = response?.data?.data || {};
  return { recent_searches };
};
export const searchJobAPI = async (query,filters) => {
  const response = await http.postAuth(`${http.apiUrl()}search/jobs?query=${query}`,filters);
  const { jobs } = response?.data?.data || {};
  return { jobs };
};

export const postJob = async (data) => {
  const response = await http.postAuth(`${http.apiUrl()}jobs/create`,data);
  const { job } = response?.data?.data || {};
  return { job };
};
export const applyJob = async (data) => {
  const response = await http.postAuth(`${http.apiUrl()}jobs/apply`,data);
  const { success,error } = response?.data || {};
  return { success,error };
};

export const getJobs = async () => {
  const response = await http.getAuth(`${http.apiUrl()}jobs/all`);
  const { jobs } = response?.data?.data || {};
  return { jobs };
};

export const getJobsToAdmin = async (data) => {
  const response = await http.postAuth(`${http.apiUrl()}jobs/admin/all`,data);
  const { jobs, total, currentPage, pageSize } = response?.data?.data || {};
  return { jobs, total, currentPage, pageSize };
};

export const getFeaturedJobs = async () => {
  const response = await http.getAuth(`${http.apiUrl()}jobs/featured`);
  const { jobs } = response?.data?.data || {};
  return { jobs };
};

export const featuredJobs = async (data) => {
  const response = await http.postAuth(`${http.apiUrl()}jobs/featured/show`,data);
  const { jobs, total, currentPage, pageSize } = response?.data?.data || {};
  return { jobs, total, currentPage, pageSize };
};



export const toggleJobFeatured = async (data) => {
  const response = await http.postAuth(`${http.apiUrl()}jobs/toggle-featured`,data);
  const { success,error } = response?.data || {};
  return { success,error };
};

export const getFeaturedJobsAPI = async () => {
  const response = await http.getAuth(`${http.apiUrl()}jobs/featured-Jobs`);
  const { jobs } = response?.data?.data || {};
  return { jobs };
};

export const savedUserJobs = async () => {
  const response = await http.getAuth(`${http.apiUrl()}jobs/saved`);
  const { jobs } = response?.data?.data || {};
  return { jobs };
};

export const createCompany = async (data) => {
  const response = await http.postAuth(`${http.apiUrl()}company/create`,data);
  const { company } = response?.data?.data || {};
  return { company };
};

export const updateUserPreferences = async (data) => {
  const response = await http.postAuth(`${http.apiUrl()}users/update-preferences`,data);
  const { preferences } = response?.data?.data || {};
  return { preferences };
};

export const uploadResume = async (data) => {
  const response = await http.postAuth(`${http.apiUrl()}users/upload-resume`,data);
  const { success,error } = response?.data || {};
  return { success,error };
};

export const uploadUserResume = async (data) => {
  const response = await http.postAuth(`${http.apiUrl()}users/upload-user-resume`,data);
  const { success,error } = response?.data || {};
  return { success,error };
};

export const getReports = async (data) => {
  const response = await http.postAuth(`${http.apiUrl()}jobs/reports`,data);
  const { reports, total, currentPage, pageSize } = response?.data?.data || [];
  return { reports, total, currentPage, pageSize };
};

export const getReport = async (id) => {
  const response = await http.getAuth(`${http.apiUrl()}jobs/report/${id}`);
  const { report } = response?.data?.data || [];
  return { report };
};
export const denyJobApplication = async (data) => {
  const response = await http.postAuth(`${http.apiUrl()}jobs/deny-job-application`,data);
  const { success,error } = response?.data || {};
  return { success,error };
};

export const getUserResume = async () => {
  const response = await http.getAuth(`${http.apiUrl()}users/get-resume`);
  const { user_resume } = response?.data?.data || [];
  return { user_resume };
};

export const getJobsPostings = async () => {
  const response = await http.getAuth(`${http.apiUrl()}jobs/jobs-postings`);
  const { jobs } = response?.data?.data || [];
  return { jobs };
};

export const searchUsers = async (search) => {
  const response = await http.postAuth(`${http.apiUrl()}users/search`,search);
  const { users } = response?.data?.data || [];
  return { users };
};
export const getOpponentsFromChats = async () => {
  const response = await http.getAuth(`${http.apiUrl()}chat/opponents`);
  const { opponents } = response?.data?.data || [];
  return { opponents };
};




