<template>
  <div>
    <form @submit.prevent='submitPayment'>
      <div class='form_item'>
        <label for='card-number' class='label_input'>Card Number</label>
        <div class='input_pay'>
          <div id='card-number' class=''></div>
        </div>
      </div>
      <div class='form_item info_card'>
        <div class='form_i'>
          <label for='card-expiry' class='label_input'>Card Expiry</label>
          <div class='input_pay'>
            <div id='card-expiry'></div>
          </div>
        </div>
        <div class='form_i'>
          <label for='card-expiry' class='label_input'>CVC</label>
          <div class='input_pay'>
            <div id='card-cvc'></div>
          </div>
        </div>
      </div>
      <div class='go_to_back  mt-26'>
        <div class='go_back_btn cp' @click='$emit("back")'>Go back</div>
      </div>
      <button type='submit' class='btn-red ' :disabled="loading">Subscribe</button>
    </form>
  </div>
</template>
<script>
import { loadStripe } from "@stripe/stripe-js";
import { errorMessage } from "@/services/messages";
import { baseErrHandler, paySubscription } from "@/services/api";
import { mapMutations } from "vuex";

export default {
  name: "PaymentFormCard",
  props: {
    subscription: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      stripe: null,
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      loading: false
    };
  },
  mounted() {
    this.initStripe();
  },
  methods: {
    ...mapMutations([
      'setLoggedUser',
    ]),
    async initStripe() {
      this.stripe = await loadStripe("pk_live_CESOlsnbfrgzRLBb6LmJN4iX");
      //this.stripe = await loadStripe(  "pk_test_51LqkA1FchP17kR6yiOguypbyc79Jq2DZb75AWc4XeDk0GItJZrOnFQsRpoW3JHJ2yZ4hRAn6jJ08E4mMY9CoewTB00mxHYdiHt")
      const elements = this.stripe.elements();

      this.cardNumber = elements.create("cardNumber", { style: this.styleForm });
      this.cardExpiry = elements.create("cardExpiry", { style: this.styleForm });
      this.cardCvc = elements.create("cardCvc", { style: this.styleForm });

      this.cardNumber.mount("#card-number");
      this.cardExpiry.mount("#card-expiry");
      this.cardCvc.mount("#card-cvc");
    },
    async submitPayment() {
      if (typeof this.stripe !== "undefined") {
        this.loading = true;
        const { token, error } = await this.stripe.createToken(this.cardNumber);
        if (error) {
          errorMessage(error.message);
          this.loading = false;
          console.error(error);
          return;
        }
        await this.processPayment(token);
      }
    },
    async processPayment(token) {
      await baseErrHandler(async () => {
        await paySubscription({
          tokenId: token.id,
          payment_period: this.subscription.payment_period,
          plan: this.subscription.sub_val
        });
        let userData = JSON.parse(localStorage.getItem('panagenius_user'));
        userData.active_plan = this.subscription.sub_val;
        this.setLoggedUser(userData);
        this.$emit('payment')
        this.loading = false;
      });
    }
  },
  computed: {
    styleForm() {
      return {
        base: {
          color: "#959897",
          fontWeight: 500,
          fontSize: "16px",
          "::placeholder": {
            color: "#808181"
          },
          ":-webkit-autofill": {
            color: "#808181"
          }
        },
        invalid: {
          color: "#E25950",
          "::placeholder": {
            color: "#808181"
          }
        }
      };
    }
  }
};
</script>

<style scoped lang='scss'>
form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.mt-26 {
  margin-top: 26px;
}

.btn-red {
  width: 100%;
}

.input_pay {
  border-radius: 8px;
  border: 2px solid #2F3737;
  padding: 12px 16px;
}

.label_input {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #c7c7c7;
  text-align: start;
  display: flex;
}

.info_card {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.form_i {
  width: 100%;
}
.go_to_back{
  display: flex;
  justify-content: center;
}
.go_back_btn{
  color: red;
  font-size: 14px;
}
.go_back_btn:hover{
opacity: 0.7;
}
</style>