<template>
  <div class='box_mentor_card' ref='boxMentorCard' @click='toggleVideoPlay'
       :class='{width_card_auto:width_card,"is-hovered": isHovered}'
       @mouseover='mouseOverCard()'
       @mouseleave='mouseLaveCard()'>
    <div class='mentor_card'>
      <div class='banner' v-if='isShowNewBanner'>NEW</div>
      <img :class='{hide_mentor_img:showVideo}' ref='mentorImg' class='mentor_img' :src=getPhotoExpert />
      <video :class='{hide_mentor_img:!showVideo}'  ref='videoRef' class='mentor_video' playsinline loop muted>
        <source :src='getVideo' type='video/mp4'>
      </video>
      <div class='down_svg_container' @click.stop='toggleInfoCard'>
        <down-svg class='down_svg' />
      </div>
      <sound-svg v-if='!mute_video' @click.stop='toggleSound' class='sound_svg' />
      <mute-svg v-else @click.stop='toggleSound' class='sound_svg' />
      <full-width-svg class='full_width_svg' />
    </div>
    <div class='mentor_info'>
      <div class='curses_info'>
        <div class='top__curses_info'>
          <div class='sneak_peek' style='display: none'>
            <play-svg class='play_svg' />
            Sneak peek
          </div>
          <div class='lessons_info'>
            <div class='count_lesson'>{{ countLessonsCourse }} {{ isTypeShowCourse ? "Episodes" : "Lessons" }}</div>
            <div class='separator__mentor_info'></div>
            <div class='time__curses_info'>{{ getDurationAllLessons }}</div>
          </div>
          <div class='see_all_v'>See all</div>
        </div>
        <div class='key_takeaway' style='display: none'>
          <info-svg class='info_svg' />
          Key takeaway: <span class='color_light_blue'>&nbsp; being a great leader.</span>
        </div>
      </div>
      <div class='description'>
        {{ course.description }}
      </div>
    </div>
  </div>
</template>
<script>

import DownSvg from "@/components/svg/downSvg.vue";
import InfoSvg from "@/components/svg/infoSvg.vue";
import PlaySvg from "@/components/svg/playSvg.vue";
import SoundSvg from "@/components/svg/SoundSvg.vue";
import FullWidthSvg from "@/components/svg/FullWidthSvg.vue";
import http from "@/services/http_client";
import MuteSvg from "@/components/svg/muteSvg.vue";

export default {
  name: "MentorCard",
  components: { MuteSvg, FullWidthSvg, SoundSvg, PlaySvg, InfoSvg, DownSvg },
  props: {
    active_card: {
      type: Boolean,
      default: false
    },
    width_card: {
      type: String,
      default: ""
    },
    course: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      videoClicked: false,
      showText: false,
      showVideo: false,
      mute_video: true,
      isHovered: false
    };
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth < 768;
    });
  },

  methods: {
    playVideoCard(){
      this.showText = true;
      this.showVideo = true;
      this.isHovered = true;
      const video = this.$refs?.videoRef;
      if (video) {
        if (this.isMobile) {
          video.muted = true;
        }
        const playPromise = video?.play();
        if (playPromise !== undefined) {
          playPromise.then(() => {
            this.showVideo = true;
          }).catch(error => {
            console.error("Video play failed due to:", error);
          });
        }
      }
    },
    mouseOverCard() {
      if (this.isMobile) {
        return;
      }
     this.playVideoCard()
    },
    mouseLaveCard() {
      if (this.isMobile) {
        return;
      }
      const video = this.$refs?.videoRef;
      this.showText = false;
      this.showVideo = false;
      this.isHovered = false;
      this.mute_video = true;
      this.videoClicked = false;
      if (video) {
        video.currentTime = 0;
        video.pause();
        video.muted = true;
        this.showVideo = false;
        this.mute_video = true;
      }
    },
    toggleVideoPlay() {
      if (!this.isMobile) {
        this.showCourse(this.course);
        return;
      }
      if (this.videoClicked) {
        this.showCourse(this.course);
        this.videoClicked = false;
        return;
      }
      if (this.$refs.boxMentorCard.classList.contains('is-hovered')) {
        this.showCourse(this.course);
        this.videoClicked = false;
        return;
      }
      this.videoClicked = true;

      const videos = document.querySelectorAll(".box_mentors video");
      videos.forEach(async (v) => {
        await v?.pause();
        v.currentTime = 0;
      });
      const card_m = document.querySelectorAll(".box_mentor_card");
      card_m.forEach((card) => {
        card.classList.remove("is-hovered");
      });
      const mentor_img = document.querySelectorAll(".mentor_img");
      mentor_img.forEach((card) => {
        card.classList.remove("hide_mentor_img");
      });
      this.$refs.boxMentorCard.classList.add('is-hovered');
      this.$refs.mentorImg.classList.add('hide_mentor_img');
      this.showText = false;
      this.showVideo = false;
      this.isHovered = false;
      this.mute_video = true;
      this.videoClicked = false;
      this.playVideoCard();
      this.isHovered = true;
    },
    toggleInfoCard() {
      this.isHovered = !this.isHovered;
    if (!this.isHovered){
      const video = this.$refs?.videoRef;
      this.showText = false;
      this.showVideo = false;
      this.isHovered = false;
      this.mute_video = true;
      this.videoClicked = false;
      if (video) {
        video.currentTime = 0;
        video.pause();
        video.muted = true;
        this.showVideo = false;
        this.mute_video = true;
      }
    }else {
      this.showText = false;
      this.showVideo = false;
      this.mute_video = true;
      this.videoClicked = false;
      this.playVideoCard();
    }
    },
    toggleSound() {
      const video = this.$refs.videoRef;
      if (video.muted) {
        video.muted = false;
        this.mute_video = false;
      } else {
        video.muted = true;
        this.mute_video = true;
      }
    },
    async showCourse(course) {
      await this.$router.push("/" + course.slug);
      window.location.reload();
    },
    formattedTime(seconds) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);

      return `${hours}h ${minutes}m`;
    }
  },
  computed: {
    isMobile() {
      return window.innerWidth < 768;
    },
    isShowNewBanner() {
      const createdAtDate = new Date(this.course.created_at);
      const currentDate = new Date();
      const diffTime = Math.abs(currentDate - createdAtDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays < 7;
    },
    getVideo() {
      return http.uploadsUrl() + this.course?.trailer;
    },
    getPhotoExpert() {
      return http.uploadsUrl() + this.course?.expert_avatar;
    },
    countLessonsCourse() {
      return this.course?.lessons?.length || 0;
    },
    getDurationAllLessons() {
      let duration_lessons = 0;
      if (this.course?.lessons?.length) {
        this.course?.lessons.forEach((item) => {
          duration_lessons += item.duration;
        });
      }
      return this.formattedTime(duration_lessons);
    },
    isTypeShowCourse() {
      return this.course.type_course === "show";
    }

  }
};
</script>

<style scoped lang='scss'>
.description {
  color: #B4B8B6;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.full_width_svg {
  opacity: 0;
  position: absolute;
  z-index: 3;
  right: 14px;
  bottom: 12px;
  transition: opacity 0.8s ease, transform 0.8s ease;
}

.sound_svg {
  cursor: pointer;
  opacity: 0;
  position: absolute;
  z-index: 3;
  left: 14px;
  bottom: 12px;
  transition: opacity 0.8s ease, transform 0.8s ease;
}

.box_mentor_card {
  width: 306px;
  height: 500px;
  padding: 12px;
  border-radius: 24px;
  box-sizing: border-box;
  position: relative;

  .mentor_info {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 0.8s ease, transform 0.8s ease;
  }

  //&:hover .exploration_theme {
  //  left: 12px;
  //  font-size: 13px;
  //  bottom: 46px;
  //}
  //
  //&:hover .down_svg {
  //  transform: rotate(180deg);
  //}
  //
  //&:hover .full_width_svg, &:hover .sound_svg {
  //  opacity: 1;
  //  transform: translateY(0);
  //}
  //
  //&:hover .name_container {
  //  display: flex;
  //  gap: 8px;
  //  left: 12px;
  //  bottom: 62px;
  //
  //  .first_name, .last_name {
  //    color: #F3F6F4;
  //    text-shadow: 0px 0px 16px rgba(19, 22, 22, 0.10);
  //    font-size: 28px;
  //    font-style: normal;
  //    font-weight: 800;
  //    line-height: 32px;
  //    text-transform: uppercase;
  //    //transition: font-size 0.1s ease;
  //  }
  //}
  //
  //&:hover .mentor_info {
  //  opacity: 1;
  //  transform: translateY(0);
  //}

}

//.box_mentor_card:hover {
//  background: #222626;
//  height: fit-content;
//  .banner{
//    display: none;
//  }
//}

.mentor_card {
  height: 480px;
  box-sizing: border-box;
  position: relative;
}

.mentor_img {
  width: 100%;
  height: 100%;
  border-radius: 24px 24px 8px 8px;
  z-index: 1;
  object-fit: cover;
  position: absolute;
  top: 0;
}

.gradient {
  position: absolute;
  z-index: 2;
  bottom: 0;
  width: 282px;
  height: 240px;
  flex-shrink: 0;
  background: var(--Gradients-Card-Dim, linear-gradient(180deg, rgba(22, 24, 24, 0.00) 0%, rgba(22, 24, 24, 0.70) 49.37%, #161818 100%));
}

.name_container {
  position: absolute;
  bottom: 72px;
  z-index: 3;
  left: 36px;
}

.first_name {
  color: #FFF;
  text-shadow: 0 0 16px rgba(19, 22, 22, 0.10);
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px;
  text-transform: capitalize;
}

.last_name {
  color: #FFF;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: -1.4px;
  text-transform: uppercase;
}

.exploration_theme {
  color: #B4B8B6;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.2px;
  position: absolute;
  bottom: 32px;
  z-index: 3;
  left: 36px;
}

.curses_info {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.key_takeaway {
  color: #F3F6F4;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.4px;
  display: flex;
  align-items: center;
}

.color_light_blue {
  color: #00B9F4;
}

.info_svg {
  margin-right: 8px;
}

.banner {
  border-radius: 24px;
  background: rgba(243, 246, 244, 0.80);
  backdrop-filter: blur(32px);
  display: flex;
  padding: 6px 12px;
  align-items: center;
  justify-content: center;
  gap: 8px;
  box-sizing: border-box;
  position: absolute;
  top: 40px;
  left: 16px;


  color: #131616;
  text-align: center;
  font-size: 11px;
  font-style: normal;
  font-weight: 800;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}

@keyframes verticalBounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-3px);
  }
}

.down_svg_container {
  width: 50%;
  justify-content: center;
  align-items: center;
  height: 36px;
  display: flex;
  position: absolute;
  z-index: 3;
  transform: translate(0%, -50%);
  left: 25%;
  bottom: 2px;
  animation: verticalBounce 0.8s linear infinite;
}

.separator__mentor_info {
  border-radius: 8px;
  background: #616262;
  width: 1px;
  height: 8px;
  margin: 0 8px;
}

.time__curses_info {
  color: #B4B8B6;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
}

.count_lesson {
  color: #F3F6F4;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.4px;
}

.lessons_info {
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.top__curses_info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sneak_peek {
  color: #E50914;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.4px;
  display: flex;
  align-items: center;
}

.play_svg {
  margin-right: 8px;
}

.mentor_video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 24px 24px 8px 8px;
}

.box_mentor_card.width_card_auto {
  width: 100%;
  min-width: 250px;
}


.box_mentor_card.is-hovered .down_svg {
  transform: rotate(180deg);
}

.box_mentor_card.is-hovered .full_width_svg,
.box_mentor_card.is-hovered .sound_svg {
  opacity: 1;
  transform: translateY(0);
}

.box_mentor_card.is-hovered {
  background: #222626;
  height: fit-content;

  .banner {
    display: none;
  }
}

.box_mentor_card.is-hovered .name_container {
  display: flex;
  gap: 8px;
  left: 12px;
  bottom: 62px;
}

.box_mentor_card.is-hovered .mentor_info {
  opacity: 1;
  transform: translateY(0);
}

.box_mentor_card.is-hovered .name_container .first_name,
.box_mentor_card.is-hovered .name_container .last_name {
  color: #F3F6F4;
  text-shadow: 0px 0px 16px rgba(19, 22, 22, 0.10);
  font-size: 28px;
  font-weight: 800;
  line-height: 32px;
  text-transform: uppercase;
}

.see_all_v {
  color: #E50914;
  font-size: 12px;
  font-style: normal;
  font-weight: 800;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-decoration: underline
}

.hide_mentor_img {
  display: none;
}

@media (max-width: 992px) {

}
</style>