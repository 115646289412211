<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
    <g clip-path="url(#clip0_12061_1152)">
      <path d="M18.2959 8.75H15.1709C14.1354 8.75 13.2959 9.58947 13.2959 10.625C13.2959 11.6605 14.1354 12.5 15.1709 12.5H18.2959V8.75Z" fill="#959897"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4209 1.25C15.529 1.25 17.8127 1.25 19.3043 2.74159C20.7959 4.23319 20.7959 6.51693 20.7959 10.625C20.7959 14.7331 20.7959 17.0168 19.3043 18.5084C17.8127 20 15.529 20 11.4209 20C7.31283 20 5.02909 20 3.53749 18.5084C2.0459 17.0168 2.0459 14.7331 2.0459 10.625C2.0459 6.51693 2.0459 4.23319 3.53749 2.74159C5.02909 1.25 7.31283 1.25 11.4209 1.25ZM5.30526 4.50936C5.07429 4.74034 4.84737 5.1005 4.70587 6.065C4.66417 6.34924 4.63332 6.65824 4.61051 7H2.7959V14H4.59553C4.61924 14.4431 4.65423 14.833 4.70587 15.185C4.84737 16.1495 5.07429 16.5097 5.30526 16.7406C5.53623 16.9716 5.89639 17.1985 6.8609 17.34C7.90972 17.4939 9.29563 17.5 11.4209 17.5C13.5462 17.5 14.9321 17.4939 15.9809 17.34C16.9454 17.1985 17.3056 16.9716 17.5365 16.7406C17.7675 16.5097 17.9944 16.1495 18.1359 15.185C18.2898 14.1362 18.2959 12.7503 18.2959 10.625C18.2959 8.49973 18.2898 7.11382 18.1359 6.065C17.9944 5.10049 17.7675 4.74033 17.5365 4.50936C17.3056 4.27839 16.9454 4.05147 15.9809 3.90997C14.9321 3.7561 13.5462 3.75 11.4209 3.75C9.29563 3.75 7.90972 3.7561 6.8609 3.90997C5.8964 4.05147 5.53623 4.27839 5.30526 4.50936Z" fill="url(#paint0_linear_12061_1152)"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0004 14.8295C9.20486 15.625 7.98687 15.625 5.7959 15.625C3.60493 15.625 2.38693 15.625 1.59142 14.8295C0.795898 14.034 0.795898 12.816 0.795898 10.625C0.795898 8.43403 0.795898 7.21604 1.59142 6.42052C2.38693 5.625 3.60493 5.625 5.7959 5.625C7.98687 5.625 9.20486 5.625 10.0004 6.42052C10.7959 7.21604 10.7959 8.43403 10.7959 10.625C10.7959 12.816 10.7959 14.034 10.0004 14.8295ZM7.89814 12.7272C7.50038 13.125 6.89138 13.125 5.7959 13.125C4.70041 13.125 4.09142 13.125 3.69366 12.7272C3.2959 12.3295 3.2959 11.7205 3.2959 10.625C3.2959 9.52951 3.2959 8.92052 3.69366 8.52276C4.09142 8.125 4.70041 8.125 5.7959 8.125C6.89138 8.125 7.50038 8.125 7.89814 8.52276C8.2959 8.92052 8.2959 9.52951 8.2959 10.625C8.2959 11.7205 8.2959 12.3295 7.89814 12.7272Z" fill="#959897"/>
    </g>
    <defs>
      <linearGradient id="paint0_linear_12061_1152" x1="2.0459" y1="1.25" x2="20.7959" y2="20" gradientUnits="userSpaceOnUse">
        <stop offset="0.0803992" stop-color="#616262"/>
        <stop offset="0.96039" stop-color="#616262" stop-opacity="0"/>
      </linearGradient>
      <clipPath id="clip0_12061_1152">
        <rect width="20" height="20" fill="white" transform="translate(0.795898)"/>
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: "CategorySvg",

};
</script>
<style scoped lang='scss'>

</style>