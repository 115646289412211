<template>
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_12061_74)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7959 17.5C14.9382 17.5 18.2959 14.1423 18.2959 10C18.2959 5.85855 14.938 2.5 10.7959 2.5C6.65377 2.5 3.2959 5.85855 3.2959 10C3.2959 14.1423 6.65361 17.5 10.7959 17.5ZM10.7959 20C16.3189 20 20.7959 15.523 20.7959 10C20.7959 4.478 16.3189 0 10.7959 0C5.2729 0 0.795898 4.478 0.795898 10C0.795898 15.523 5.2729 20 10.7959 20Z" fill="url(#paint0_linear_12061_74)"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M15.1233 13.5213L18.2533 3.64145C18.4659 2.96607 17.8476 2.32928 17.1714 2.54154L7.27902 5.629C6.87328 5.76408 6.54482 6.07282 6.4289 6.47805L3.33754 16.3772C3.12501 17.0333 3.7626 17.672 4.41952 17.4578L14.2732 14.3723C14.679 14.2546 15.0074 13.9265 15.1233 13.5213ZM12.9596 11.7607L14.5246 6.82072C14.6309 6.48303 14.3217 6.16464 13.9836 6.27077L9.03746 7.8145C8.83459 7.88204 8.67036 8.03641 8.6124 8.23903L7.06672 13.1886C6.96045 13.5167 7.27925 13.836 7.60771 13.7289L12.5346 12.1861C12.7374 12.1273 12.9017 11.9633 12.9596 11.7607Z" fill="#F0D800"/>
      <mask id="mask0_12061_74" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="3" y="2" width="16" height="16">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.1233 13.5213L18.2533 3.64145C18.4659 2.96607 17.8476 2.32928 17.1714 2.54154L7.27902 5.629C6.87328 5.76408 6.54482 6.07282 6.4289 6.47805L3.33754 16.3772C3.12501 17.0333 3.7626 17.672 4.41952 17.4578L14.2732 14.3723C14.679 14.2546 15.0074 13.9265 15.1233 13.5213ZM12.9596 11.7607L14.5246 6.82072C14.6309 6.48303 14.3217 6.16464 13.9836 6.27077L9.03746 7.8145C8.83459 7.88204 8.67036 8.03641 8.6124 8.23903L7.06672 13.1886C6.96045 13.5167 7.27925 13.836 7.60771 13.7289L12.5346 12.1861C12.7374 12.1273 12.9017 11.9633 12.9596 11.7607Z" fill="url(#paint1_linear_12061_74)"/>
      </mask>
      <g mask="url(#mask0_12061_74)">
        <path opacity="0.25" d="M17.1714 2.54154L7.27902 5.629C6.87328 5.76408 6.54482 6.07282 6.4289 6.47805L3.33754 16.3772C3.23698 16.6876 3.32674 16.9942 3.51881 17.2083L18.0675 2.78178C17.8557 2.54922 17.5213 2.43171 17.1714 2.54154Z" fill="white"/>
      </g>
    </g>
    <defs>
      <linearGradient id="paint0_linear_12061_74" x1="0.795898" y1="0" x2="20.7959" y2="20" gradientUnits="userSpaceOnUse">
        <stop offset="0.0803992" stop-color="#04EB98"/>
        <stop offset="0.96039" stop-color="#04EB98" stop-opacity="0"/>
      </linearGradient>
      <linearGradient id="paint1_linear_12061_74" x1="7.0459" y1="6.25" x2="15.1709" y2="16.25" gradientUnits="userSpaceOnUse">
        <stop stop-color="#F0D800"/>
        <stop offset="0.971669" stop-color="#F48A00"/>
      </linearGradient>
      <clipPath id="clip0_12061_74">
        <rect width="20" height="20" fill="white" transform="translate(0.795898)"/>
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Explore",

};
</script>
<style scoped lang='scss'>

</style>