<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.70095 0.544549C7.02162 1.07673 6.85016 1.76809 6.31798 2.08876C4.35481 3.27168 3.0459 5.42111 3.0459 7.87517C3.0459 9.31079 3.49282 10.6387 4.25513 11.7316C4.61059 12.2412 4.48563 12.9424 3.97603 13.2979C3.46644 13.6533 2.76517 13.5284 2.40972 13.0188C1.39256 11.5605 0.795898 9.78585 0.795898 7.87517C0.795898 4.59999 2.54604 1.73467 5.15674 0.161577C5.68892 -0.159089 6.38029 0.012373 6.70095 0.544549ZM12.8908 0.544549C13.2115 0.012373 13.9029 -0.159089 14.4351 0.161577C17.0458 1.73467 18.7959 4.59999 18.7959 7.87517C18.7959 9.78585 18.1992 11.5605 17.1821 13.0188C16.8266 13.5284 16.1254 13.6533 15.6158 13.2979C15.1062 12.9424 14.9812 12.2412 15.3367 11.7316C16.099 10.6387 16.5459 9.31079 16.5459 7.87517C16.5459 5.42111 15.237 3.27168 13.2738 2.08876C12.7416 1.76809 12.5702 1.07673 12.8908 0.544549Z" fill="url(#paint0_linear_12061_715)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.63844 9.59708C5.41229 9.05111 5.2959 8.46595 5.2959 7.875C5.2959 7.28405 5.41229 6.69889 5.63844 6.15292C5.86459 5.60696 6.19605 5.11088 6.61392 4.69302C7.03178 4.27516 7.52786 3.94369 8.07382 3.71754C8.61979 3.4914 9.20495 3.375 9.7959 3.375C10.3868 3.375 10.972 3.4914 11.518 3.71754C12.0639 3.94369 12.56 4.27516 12.9779 4.69302C13.3957 5.11088 13.7272 5.60696 13.9534 6.15292C14.1795 6.69889 14.2959 7.28405 14.2959 7.875C14.2959 8.46595 14.1795 9.05111 13.9534 9.59708C13.7272 10.143 13.3957 10.6391 12.9779 11.057C12.56 11.4748 12.0639 11.8063 11.518 12.0325C10.972 12.2586 10.3868 12.375 9.7959 12.375C9.20495 12.375 8.61979 12.2586 8.07382 12.0325C7.52786 11.8063 7.03178 11.4748 6.61392 11.057C6.19605 10.6391 5.86459 10.143 5.63844 9.59708ZM7.71717 8.73604C7.6041 8.46306 7.5459 8.17047 7.5459 7.875C7.5459 7.57953 7.6041 7.28694 7.71717 7.01396C7.83024 6.74098 7.99598 6.49294 8.20491 6.28401C8.41384 6.07508 8.66188 5.90934 8.93486 5.79627C9.20784 5.6832 9.50042 5.625 9.7959 5.625C10.0914 5.625 10.384 5.6832 10.6569 5.79627C10.9299 5.90934 11.178 6.07508 11.3869 6.28401C11.5958 6.49294 11.7616 6.74098 11.8746 7.01396C11.9877 7.28694 12.0459 7.57953 12.0459 7.875C12.0459 8.17047 11.9877 8.46306 11.8746 8.73604C11.7616 9.00902 11.5958 9.25706 11.3869 9.46599C11.178 9.67492 10.9299 9.84066 10.6569 9.95373C10.384 10.0668 10.0914 10.125 9.7959 10.125C9.50042 10.125 9.20784 10.0668 8.93486 9.95373C8.66188 9.84066 8.41384 9.67492 8.20491 9.46599C7.99598 9.25706 7.83024 9.00902 7.71717 8.73604Z" fill="#959897"/>
    <path d="M14.2905 18C14.5004 18 14.7062 17.9564 14.8848 17.874C15.0634 17.7917 15.2077 17.6739 15.3016 17.5338C15.3954 17.3937 15.4352 17.2368 15.4163 17.0808C15.3975 16.9248 15.3208 16.7758 15.1948 16.6505C14.569 16.022 13.7548 15.5116 12.8174 15.1599C11.8799 14.8082 10.8452 14.625 9.7959 14.625C8.74661 14.625 7.71187 14.8082 6.77441 15.1599C5.83695 15.5116 5.02278 16.022 4.39698 16.6505C4.27102 16.7758 4.19432 16.9248 4.17547 17.0808C4.15661 17.2368 4.19636 17.3937 4.29024 17.5338C4.38413 17.6739 4.52844 17.7917 4.70702 17.874C4.8856 17.9564 5.09139 18 5.30132 18H14.2905Z" fill="#959897"/>
    <defs>
      <linearGradient id="paint0_linear_12061_715" x1="0.795898" y1="0" x2="13.7562" y2="17.2801" gradientUnits="userSpaceOnUse">
        <stop offset="0.0803992" stop-color="#616262"/>
        <stop offset="0.96039" stop-color="#616262" stop-opacity="0"/>
      </linearGradient>
    </defs>
  </svg></template>
<script>
export default {
  name: "AboutSvg",

};
</script>
<style scoped lang='scss'>

</style>