<template>
  <div class='container_mentor'>
    <div class='gradient__card_left' @mouseenter='startScroll("left")' @mouseleave='stopScroll'></div>
    <div class='box_mentors'>
      <div class='cp'  v-for='(course,index) in courses' :key='index'>
        <mentor-card :course='course' />
      </div>
    </div>
    <div class='gradient__card' @mouseenter='startScroll("right")' @mouseleave='stopScroll'></div>
  </div>
</template>
<script>
import MentorCard from "@/components/MentorCard.vue";

export default {
  name: "MentorCardsContainer",
  components: { MentorCard },
  props:{
    courses:{
      type:Array,
      default:()=>{
        return []
      }
    }
  },
  data(){
    return {
      scrollInterval: null,
    }
  },
  mounted() {
  },
  methods:{
    startScroll(direction) {
      const container = document.querySelector(".box_mentors");
      this.stopScroll();
      this.scrollInterval = setInterval(() => {
        if (direction === "right") {
          container.scrollLeft += 10;
        } else if (direction === "left") {
          container.scrollLeft -= 10;
        }
      }, 30);
    },
    stopScroll() {
      if (this.scrollInterval) {
        clearInterval(this.scrollInterval);
        this.scrollInterval = null;
      }
    },
   async showCourse(course){
      await this.$router.push('/'+course.slug);
      window.location.reload()
    },
  },
  computed: {
    isMobile() {
      return window.innerWidth < 768;
    }
  },
};
</script>
<style scoped lang='scss'>
.box_mentors {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none;
  position: relative;
  //width: calc(100vw);
  //padding-right: 108px;
  box-sizing: border-box;
  //left: -120px;
  //padding-left: 108px;
}

.box_mentors::-webkit-scrollbar {
  display: none;
}

.item__card {
  width: 100%;
}
.gradient__card {
  opacity: 0.92;
  background: #131616;
  position: absolute;
  display: none;
  //right: -120px;
  top: 0;
  z-index: 1;
  width: 108px;
  height: 100%;
  background: linear-gradient(90deg, rgba(22, 24, 24, 0) 0, rgba(22, 24, 24, 1) 17.37%, #161818 100%);
}

.gradient__card_left {
  opacity: 0.92;
  background: #131616;
  position: absolute;
  //left: -120px;
  top: 0;
  width: 108px;
  height: 100%;
  z-index: 5;
  display: none;
  background: linear-gradient(270deg, rgba(22, 24, 24, 0) 0, rgba(22, 24, 24, 1) 17.37%, #161818 100%);
}

.container_mentor {
  position: relative;
}

@media (max-width: 992px) {
  .box_mentors {
    width: 100vw;
    padding-right: 16px;
    padding-left: 16px;
    left: -16px;
  }
  .gradient__card {
    width: 16px;
    right: -16px;
    display: none;
  }
  .gradient__card_left {
    left: -16px;
    width: 16px;
    display: none;
  }
}

</style>